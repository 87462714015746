import {FEATURES} from "../../constants/Educational";
import {ROLES} from "../../constants";

export const formatSchoolDetailsResponse = (response) => {
    const { id, name, screens, roles, memberId } = response;

    const isAdminUser = roles && roles.includes(ROLES.ADMIN);
    let isFacultyMember = false;

    if (!isAdminUser) {
        isFacultyMember = roles && roles.includes(ROLES.STAFF);
    }

    const features = screens ? screens
            .filter(screen => FEATURES[screen.name])
            .map(screen => FEATURES[screen.name])
        : [];

    return {
        id,
        name,
        features,
        isAdminUser,
        isFacultyMember,
        memberId,
    }
}

const getDivisionValues = (sectionTypeValues) => {
    return sectionTypeValues.map(typeValues => {
        return {
            id: typeValues.id,
            priority: typeValues.priority,
            displayValue: typeValues.displayValue,
            deletable: typeValues.deletable,
        }
    })
}

export const formatDivisionsAndEntityResponse = (response) => {
    const { sectionTypeKeys, sections } = response;
    const divisions = sectionTypeKeys.map(sectionType => {
        return {
            displayName: sectionType.displayName,
            id: sectionType.id,
            priority: sectionType.priority,
            deletable: sectionType.deletable,
            divisionValues: getDivisionValues(sectionType.sectionTypeValues)
        }
    });


    const entities = sections.map(section => {
        return {
            name: section.sectionStr,
            id: section.id,
            entries: section.sectionEntries.map(entry => {
                return {
                    displayName: entry.sectionTypeValueSelected.sectionTypeKeySelected.displayName,
                    displayValue: entry.sectionTypeValueSelected.displayValue,
                    priority: entry.sectionTypeValueSelected.sectionTypeKeySelected.priority,
                }
            }).sort((a, b) => a.priority - b.priority),
        }
    });

    return { divisions, entities };
}