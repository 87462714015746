import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";
import {
    GET_METHOD,
    POST_METHOD,
    DELETE_METHOD,
    CREATE_ZOOM_MEETING_URL,
    CANCEL_ZOOM_MEETING_URL,
    GET_ZOOM_MEETINGS_BY_FACULTY_ID,
    GET_ZOOM_MEETINGS_BY_STUDENT_ID,
    GET_ZOOM_MEETINGS_BY_ORGANIZATION_ID_URL,
    GET_GOOGLE_MEET_MEETINGS_BY_ORGANIZATION_ID_URL,
    GET_GOOGLE_MEET_MEETINGS_BY_FACULTY_ID_URL,
    GET_GOOGLE_MEET_MEETINGS_BY_STUDENT_ID_URL,
    CANCEL_GOOGLE_MEET_MEETING_URL,
    CREATE_GOOGLE_MEET_MEETING_URL,
    UPDATE_GOOGLE_MEET_MEETING_URL,
    GET_GOOGLE_MEET_DETAIL_BY_ID_URL,
    CREATE_MICROSOFT_TEAM_MEETING_URL,
    GET_MICROSOFT_TEAM_MEETINGS_BY_ORGANIZATION_ID_URL,
    GET_MICROSOFT_TEAM_MEETINGS_BY_FACULTY_ID_URL,
    GET_MICROSOFT_TEAM_MEETINGS_BY_STUDENT_ID_URL,
    CANCEL_MICROSOFT_TEAM_MEETING_URL,
    UPDATE_MICROSOFT_TEAM_MEETING_URL,
    GET_MICROSOFT_TEAM_DETAIL_BY_ID_URL,
} from "../../constants/urls";
import {appendPaginationQueryParams, formatMeetingDateTime} from "../../utils/utils";
import {ONLINE_TUTORIAL_MODE} from "../../constants/Educational";

export const getZoomMeetingsByOrganizationIdService = (payload, schoolId) => {
    const requestInfo = getRequestInfo(GET_ZOOM_MEETINGS_BY_ORGANIZATION_ID_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const getZoomMeetingsByFacultyIdService = (payload, schoolId, facultyId) => {
    const requestInfo = getRequestInfo(GET_ZOOM_MEETINGS_BY_FACULTY_ID);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{facultyId}', facultyId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const getZoomMeetingsByStudentIdService = (payload, schoolId, studentId) => {
    const requestInfo = getRequestInfo(GET_ZOOM_MEETINGS_BY_STUDENT_ID);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{studentId}', studentId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const getMeetingsByOrganizationIdService = (payload, meetingType, schoolId) => {

    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(GET_GOOGLE_MEET_MEETINGS_BY_ORGANIZATION_ID_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(GET_MICROSOFT_TEAM_MEETINGS_BY_ORGANIZATION_ID_URL);
    }

    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload)
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const getMeetingsByFacultyIdService = (payload, meetingType, schoolId, facultyId) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(GET_GOOGLE_MEET_MEETINGS_BY_FACULTY_ID_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(GET_MICROSOFT_TEAM_MEETINGS_BY_FACULTY_ID_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{facultyId}', facultyId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload)
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const getMeetingsByStudentIdService = (payload, meetingType, schoolId, studentId) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(GET_GOOGLE_MEET_MEETINGS_BY_STUDENT_ID_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(GET_MICROSOFT_TEAM_MEETINGS_BY_STUDENT_ID_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{studentId}', studentId);
    requestInfo.url = appendPaginationQueryParams(requestInfo.url, payload)
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export const cancelZoomMeetingService = (schoolId, meetingNumber) => {
    const requestInfo = getRequestInfo(CANCEL_ZOOM_MEETING_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{meetingId}', meetingNumber);
    return callHandleServiceRequest(requestInfo, DELETE_METHOD, null);
}

export const cancelMeetingService = (schoolId, meetingType, meetingNumber) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(CANCEL_GOOGLE_MEET_MEETING_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(CANCEL_MICROSOFT_TEAM_MEETING_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{meetingId}', meetingNumber);
    return callHandleServiceRequest(requestInfo, DELETE_METHOD, null);
}

export const createZoomMeetingService = (schoolId, meetingDetails, isInstantMeeting) => {
    const requestInfo = getRequestInfo(CREATE_ZOOM_MEETING_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    const payLoad = {
        schoolId,
        sectionId: meetingDetails.entity,
        subjectId: meetingDetails.subject,
        facultyId: meetingDetails.faculty,
        data: {
            topic: meetingDetails.topic,
            duration: parseInt(meetingDetails.duration),
            password: "",
            agenda: meetingDetails.agenda,
            settings: {
                host_video: false,
                in_meeting: true,
                join_before_host: true,
                audio: "both",
                auto_recording: "local"
            }
        },
    };

    if (isInstantMeeting) {
        payLoad.data.type = 1;
    } else {
        payLoad.data.type = 2;
        payLoad.data.timezone = "Asia/Kolkata";
        payLoad.data.start_time = formatMeetingDateTime(meetingDetails.scheduleDate, meetingDetails.scheduleTime);
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export const createMeetingService = (schoolId, meetingDetails, meetingType, isInstantMeeting) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(CREATE_GOOGLE_MEET_MEETING_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(CREATE_MICROSOFT_TEAM_MEETING_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    const payLoad = {
        schoolId,
        topic: meetingDetails.topic,
        agenda: meetingDetails.agenda,
        sectionId: meetingDetails.entity,
        subjectId: meetingDetails.subject,
        facultyId: meetingDetails.faculty,
        duration: parseInt(meetingDetails.duration),
        instantMeeting: isInstantMeeting,
    };

    if (!isInstantMeeting) {
        payLoad.startTime = formatMeetingDateTime(meetingDetails.scheduleDate, meetingDetails.scheduleTime);
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export const updateMeetingService = (schoolId, meetingNumber, meetingLink, meetingType) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(UPDATE_GOOGLE_MEET_MEETING_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(UPDATE_MICROSOFT_TEAM_MEETING_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify({id: meetingNumber, meetLink: meetingLink, active: 1}));
}

export const getMeetingDetailsByIdService = (schoolId, meetingType, meetingNumber) => {
    let requestInfo;
    if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
        requestInfo = getRequestInfo(GET_GOOGLE_MEET_DETAIL_BY_ID_URL);
    } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
        requestInfo = getRequestInfo(GET_MICROSOFT_TEAM_DETAIL_BY_ID_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{meetingId}', meetingNumber);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}