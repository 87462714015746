export const SET_SCHOOL_DETAILS = "SET_SCHOOL_DETAILS";
export const GET_EDUCATIONAL_FEATURES = "GET_EDUCATIONAL_FEATURES";
export const SET_DIVISIONS_AND_ENTITY_DATA = "SET_DIVISIONS_AND_ENTITY_DATA";

// Divisions
export const ADD_DIVISION = "ADD_DIVISION";
export const DELETE_DIVISION = "DELETE_DIVISION";
export const ADD_DIVISION_VALUE = "ADD_DIVISION_VALUE";
export const DELETE_DIVISION_VALUE = "DELETE_DIVISION_VALUE";

export const ADD_ENTITY = "ADD_ENTITY";
export const DELETE_ENTITY = "DELETE_ENTITY";

// Members
export const ADD_ADMISSION_FORM = "ADD_ADMISSION_FORM";
export const ADD_OR_EDIT_FACULTY = "ADD_OR_EDIT_FACULTY";
export const DELETE_FACULTY = "DELETE_FACULTY";
export const SET_FACULTY_LIST = "SET_FACULTY_LIST";
export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const SET_FACULTY_DETAILS_BY_ID = "SET_FACULTY_DETAILS_BY_ID";
export const SET_STUDENT_DETAILS_BY_ID = "SET_STUDENT_DETAILS_BY_ID";
export const GET_FACULTY_DETAILS_BY_ID = "GET_FACULTY_DETAILS_BY_ID";
export const SET_FACULTY_API_MESSAGE = "SET_FACULTY_API_MESSAGE";
export const SET_STUDENT_API_MESSAGE = "SET_STUDENT_API_MESSAGE";
export const CLEAR_API_MESSAGES = "CLEAR_API_MESSAGES";

export const ADD_OR_EDIT_STUDENT = "ADD_OR_EDIT_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const SET_SELECTED_ENTITY_ID_FOR_MEMBERS = "SET_SELECTED_ENTITY_ID_FOR_MEMBERS";

// Subjects
export const ADD_SUBJECT = "ADD_SUBJECT";
export const ASSIGN_ENTITY_TO_SUBJECT = "ASSIGN_ENTITY_TO_SUBJECT";
export const ASSIGN_FACULTY_TO_SUBJECT = "ASSIGN_FACULTY_TO_SUBJECT";
export const ASSIGN_STUDENTS_TO_SUBJECT = "ASSIGN_STUDENTS_TO_SUBJECT";
export const GET_STUDENTS_BY_MULTIPLE_ENTITIES = "GET_STUDENTS_BY_MULTIPLE_ENTITIES";
export const SET_STUDENTS_LIST_BY_MULTIPLE_ENTITIES = "SET_STUDENTS_LIST_BY_MULTIPLE_ENTITIES";
export const SET_SUBJECTS_DATA = "SET_SUBJECTS_DATA";

// Attendance
export const SET_SUBJECTS_BY_SECTIONS = "SET_SUBJECTS_BY_SECTIONS";
export const SET_STUDENTS_FOR_ADD_ATTENDANCE = "SET_STUDENTS_FOR_ADD_ATTENDANCE";
export const GET_STUDENTS_FOR_ADD_ATTENDANCE = "GET_STUDENTS_FOR_ADD_ATTENDANCE";
export const GET_STUDENT_AUTO_COMPLETE_ATTENDANCE = "GET_STUDENT_AUTO_COMPLETE_ATTENDANCE";
export const ADD_ATTENDANCE = "ADD_ATTENDANCE";
export const GET_ATTENDANCE_RECORDS = "GET_ATTENDANCE_RECORDS";
export const DELETE_ATTENDANCE_RECORDS = "DELETE_ATTENDANCE_RECORDS";
export const EDIT_ATTENDANCE_RECORDS = "EDIT_ATTENDANCE_RECORDS";

// Marks
export const SET_EXAMS_AND_SUBJECTS_BY_SECTIONS = "SET_EXAMS_AND_SUBJECTS_BY_SECTIONS"
export const SET_STUDENTS_FOR_ADD_MARKS = "SET_STUDENTS_FOR_ADD_MARKS";
export const GET_STUDENTS_FOR_ADD_MARKS = "GET_STUDENTS_FOR_ADD_MARKS";
export const GET_STUDENT_AUTO_COMPLETE_MARKS = "GET_STUDENT_AUTO_COMPLETE_MARKS";
export const ADD_MARKS = "ADD_MARKS";
export const GET_MARKS_RECORDS = "GET_MARKS_RECORDS";
export const DELETE_MARKS_RECORDS = "DELETE_MARKS_RECORDS";
export const EDIT_MARKS_RECORDS = "EDIT_MARKS_RECORDS";

// EXAM
export const ADD_EXAM = "ADD_EXAM";
export const GET_EXAM_RECORDS = "GET_EXAM_RECORDS";
export const DELETE_EXAM_RECORDS = "DELETE_EXAM_RECORDS";
export const EDIT_EXAM_RECORDS = "EDIT_EXAM_RECORDS";

// Online classes
export const GET_ZOOM_MEETING_LIST = "GET_ZOOM_MEETING_LIST";
export const SET_ZOOM_MEETING_LIST = "SET_ZOOM_MEETING_LIST";
export const CREATE_ZOOM_MEETING = "CREATE_ZOOM_MEETING";
export const CANCEL_ZOOM_MEETING = "CANCEL_ZOOM_MEETING";

export const GET_MEETING_LIST = "GET_MEETING_LIST";
export const SET_GOOGLE_MEET_MEETING_LIST = "SET_GOOGLE_MEET_MEETING_LIST";
export const CREATE_MEETING = "CREATE_MEETING";
export const CANCEL_MEETING = "CANCEL_MEETING";
export const UPDATE_MEETING_URL = "UPDATE_MEETING_URL";
export const GET_MEETING_DETAILS_BY_ID = "GET_MEETING_DETAILS_BY_ID";

export function setSchoolDetails(school) {
    return {
        type: SET_SCHOOL_DETAILS,
        school,
    }
}

export function setDivisionsAndEntityData(divisions, entities) {
    return {
        type: SET_DIVISIONS_AND_ENTITY_DATA,
        divisions,
        entities,
    }
}

export function addDivision(division, callback) {
    return {
        type: ADD_DIVISION,
        division,
        callback,
    }
}

export function addDivisionValue(divisionValue, selectedDivisionId, callback) {
    return {
        type: ADD_DIVISION_VALUE,
        divisionValue,
        selectedDivisionId,
        callback,
    }
}

export function deleteDivisionValue(divisionValueId, callback) {
    return {
        type: DELETE_DIVISION_VALUE,
        divisionValueId,
        callback,
    }
}

export function addEntity(entityFields, callback) {
    return {
        type: ADD_ENTITY,
        entityFields,
        callback,
    }
}

export function deleteEntity(entityId, callback) {
    return {
        type: DELETE_ENTITY,
        entityId,
        callback,
    }
}

export function deleteDivision(divisionId, callback) {
    return {
        type: DELETE_DIVISION,
        divisionId,
        callback,
    }
}

export function addAdmissionForm(admissionFormData, callback) {
    return {
        type: ADD_ADMISSION_FORM,
        admissionFormData,
        callback,
    }
}

export function addOrEditFaculty(facultyForm, callback) {
    return {
        type: ADD_OR_EDIT_FACULTY,
        facultyForm,
        callback,
    }
}

export function setFacultyList(facultyList) {
    return {
        type: SET_FACULTY_LIST,
        facultyList,
    }
}

export function setStudentList(studentList) {
    return {
        type: SET_STUDENT_LIST,
        studentList,
    }
}

export function setFacultyDetailsById(facultyDetails, originalFacultyDetails) {
    return {
        type: SET_FACULTY_DETAILS_BY_ID,
        facultyDetails,
        originalFacultyDetails,
    }
}

export function setStudentDetailsById(studentDetails, originalStudentDetails) {
    return {
        type: SET_STUDENT_DETAILS_BY_ID,
        studentDetails,
        originalStudentDetails,
    }
}

export function clearAPiMessages() {
    return {
        type: CLEAR_API_MESSAGES,
    }
}

export function deleteFaculty(facultyId) {
    return {
        type: DELETE_FACULTY,
        facultyId,
    }
}

export function setFacultyApiSuccessMessage() {
    return {
        type: SET_FACULTY_API_MESSAGE,
    }
}

export function setStudentApiSuccessMessage() {
    return {
        type: SET_STUDENT_API_MESSAGE,
    }
}

export function getFacultyDetailsById(facultyId, callback) {
    return {
        type: GET_FACULTY_DETAILS_BY_ID,
        facultyId,
        callback,
    }
}

export function setSelectedEntityIdForMembers(entityId) {
    return {
        type: SET_SELECTED_ENTITY_ID_FOR_MEMBERS,
        entityId,
    }
}

export function addOrEditStudent(studentForm, callback) {
    return {
        type: ADD_OR_EDIT_STUDENT,
        studentForm,
        callback,
    }
}

export function deleteStudent(studentId) {
    return {
        type: DELETE_STUDENT,
        studentId,
    }
}

export function addSubject(subjectDetails, callback) {
    return {
        type: ADD_SUBJECT,
        subjectDetails,
        callback,
    }
}

export function assignEntityToSubject(subjectId, entityIds, callback) {
    return {
        type: ASSIGN_ENTITY_TO_SUBJECT,
        subjectId,
        entityIds,
        callback,
    }
}

export function assignFacultyToSubject(subjectId, facultyIds, callback) {
    return {
        type: ASSIGN_FACULTY_TO_SUBJECT,
        subjectId,
        facultyIds,
        callback,
    }
}

export function assignStudentsToSubject(subjectId, studentIds, callback) {
    return {
        type: ASSIGN_STUDENTS_TO_SUBJECT,
        subjectId,
        studentIds,
        callback,
    }
}

export function getStudentsByEntities(entities, callback) {
    return {
        type: GET_STUDENTS_BY_MULTIPLE_ENTITIES,
        entities,
        callback,
    }
}

export function setSubjectsData(subjects) {
    return {
        type: SET_SUBJECTS_DATA,
        subjects,
    }
}

export function setSubjectsBySectionsData(subjectsBySections) {
    return {
        type: SET_SUBJECTS_BY_SECTIONS,
        subjectsBySections,
    }
}

export function setExamsAndSubjectsBySectionsData(examsAndSubjectsBySections) {
    return {
        type: SET_EXAMS_AND_SUBJECTS_BY_SECTIONS,
        examsAndSubjectsBySections,
    }
}

export function setStudentsForAddAttendance(studentsList) {
    return {
        type: SET_STUDENTS_FOR_ADD_ATTENDANCE,
        studentsList,
    }
}

export function getStudentsForAddAttendance(sectionId, subjectId, callback) {
    return {
        type: GET_STUDENTS_FOR_ADD_ATTENDANCE,
        sectionId,
        subjectId,
        callback,
    }
}

export function getStudentAutoCompleteAttendance(studentName, sectionId, callBack) {
   return {
       type: GET_STUDENT_AUTO_COMPLETE_ATTENDANCE,
       studentName,
       sectionId,
       callBack,
   }
}

export function getAttendanceRecords(payload, callBack) {
    return {
        type: GET_ATTENDANCE_RECORDS,
        payload,
        callBack,
    }
}

export function addAttendance(payload, callback) {
    return {
        type: ADD_ATTENDANCE,
        payload,
        callback,
    }
}

export function deleteAttendance(attendanceId, callback) {
    return {
        type: DELETE_ATTENDANCE_RECORDS,
        attendanceId,
        callback,
    }
}

export function editAttendanceRecords(payload, callback) {
    return {
        type: EDIT_ATTENDANCE_RECORDS,
        payload,
        callback,
    }
}
export function setExamsAndSubjectsBySections(examsAndSubjectsBySections) {
    return {
        type: SET_EXAMS_AND_SUBJECTS_BY_SECTIONS,
        examsAndSubjectsBySections,
    }
}

export function setStudentsForAddMarks(studentsList) {
    return {
        type: SET_STUDENTS_FOR_ADD_MARKS,
        studentsList,
    }
}

export function getStudentsForAddMarks(sectionId, subjectId, callback) {
    return {
        type: GET_STUDENTS_FOR_ADD_MARKS,
        sectionId,
        subjectId,
        callback,
    }
}

export function getStudentAutoCompleteMarks(studentName, sectionId, callBack) {
   return {
       type: GET_STUDENT_AUTO_COMPLETE_MARKS,
       studentName,
       sectionId,
       callBack,
   }
}

export function getMarksRecords(payload, callBack) {
    return {
        type: GET_MARKS_RECORDS,
        payload,
        callBack,
    }
}

export function addMarks(payload, callback) {
    return {
        type: ADD_MARKS,
        payload,
        callback,
    }
}

export function deleteMarks(marksId, callback) {
    return {
        type: DELETE_MARKS_RECORDS,
        marksId,
        callback,
    }
}

export function editMarksRecords(payload, callback) {
    return {
        type: EDIT_MARKS_RECORDS,
        payload,
        callback,
    }
}

export function getExamRecords(payload, callBack) {
    return {
        type: GET_EXAM_RECORDS,
        payload,
        callBack,
    }
}

export function addExam(payload, callback) {
    return {
        type: ADD_EXAM,
        payload,
        callback,
    }
}

export function deleteExam(marksId, callback) {
    return {
        type: DELETE_EXAM_RECORDS,
        marksId,
        callback,
    }
}

export function editExamRecords(payload, callback) {
    return {
        type: EDIT_EXAM_RECORDS,
        payload,
        callback,
    }
}


export function setStudentsListByMultipleEntities(studentsList) {
    return {
        type: SET_STUDENTS_LIST_BY_MULTIPLE_ENTITIES,
        studentsList,
    }
}

export function getZoomMeetingList(payload, callback) {
    return {
        type: GET_ZOOM_MEETING_LIST,
        payload,
        callback,
    }
}

export function setZoomMeetingList({ scheduledZoomMeetings, completedZoomMeetings }) {
    return {
        type: SET_ZOOM_MEETING_LIST,
        scheduledZoomMeetings,
        completedZoomMeetings,
    }
}

export function getMeetingList(payload, meetingType, callback) {
    return {
        type: GET_MEETING_LIST,
        payload,
        meetingType,
        callback,
    }
}

export function setMeetingList({ scheduledMeetings, completedMeetings }) {
    return {
        type: SET_GOOGLE_MEET_MEETING_LIST,
        scheduledMeetings,
        completedMeetings,
    }
}

export function createZoomMeeting(meetingDetails, isInstantMeeting, callback) {
    return {
        type: CREATE_ZOOM_MEETING,
        meetingDetails,
        isInstantMeeting,
        callback,
    }
}

export function cancelZoomMeeting(meetingNumber, callback) {
    return {
        type: CANCEL_ZOOM_MEETING,
        meetingNumber,
        callback
    }
}

export function createMeeting(meetingDetails, isInstantMeeting, meetingType, callback) {
    return {
        type: CREATE_MEETING,
        meetingDetails,
        isInstantMeeting,
        meetingType,
        callback,
    }
}

export function cancelMeeting(meetingNumber, meetingType, callback) {
    return {
        type: CANCEL_MEETING,
        meetingNumber,
        meetingType,
        callback,
    }
}

export function updateMeetingLink(meetingNumber, meetingLink, meetingType, callback) {
    return {
        type: UPDATE_MEETING_URL,
        meetingNumber,
        meetingLink,
        meetingType,
        callback,
    }
}

export function getMeetingDetailsById(meetingNumber, meetingType, callback) {
    return {
        type: GET_MEETING_DETAILS_BY_ID,
        meetingNumber,
        meetingType,
        callback,
    }
}