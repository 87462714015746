import React from 'react';

const Footer = () => {
	return (
		 <footer className="ftco-footer ftco-section">
      		<div className="container">
         		<div className="row text-center">
          			<div className="col-md-12 text-center">
          				 <div className="copy-right">Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved <i className="icon-heart color-danger" aria-hidden="true"></i> by <a href="http://www.rapsoltechnologies.com" target="_blank" rel="noopener noreferrer">Rapsol Technologies Private Limited</a></div>
          			</div>
        		</div>
      		</div>
    	</footer>
	);
}

export default Footer;