import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";
import {
    GET_ALL_SUBJECTS_SECTIONS,
    GET_STUDENTS_BY_SECTION_AND_SUBJECT,
    ADD_ATTENDANCE,
    GET_STUDENTS_AUTO_COMPLETE,
    SEARCH_ATTENDANCE_RECORDS,
    DELETE_ATTENDANCE_RECORDS_URL,
    EDIT_ATTENDANCE_RECORDS_URL,
    GET_METHOD,
    POST_METHOD,
} from "../../constants/urls";

export function getAllSubjectsBySectionsService(schoolId) {
    const requestInfo = getRequestInfo(GET_ALL_SUBJECTS_SECTIONS);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getStudentsBySectionAndSubjectService(schoolId, sectionId, subjectId) {
    const requestInfo = getRequestInfo(GET_STUDENTS_BY_SECTION_AND_SUBJECT);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{subjectId}', subjectId);
    requestInfo.url = requestInfo.url.replace('{sectionId}', sectionId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}


export function addAttendanceService(schoolId, payload) {
    const requestInfo = getRequestInfo(ADD_ATTENDANCE);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}

export function getStudentAutoCompleteService(schoolId, studentName, sectionId) {
    const requestInfo = getRequestInfo(GET_STUDENTS_AUTO_COMPLETE);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?name=${studentName}`;
    if (sectionId) {
        requestInfo.url = `${requestInfo.url}&sectionId=${sectionId}`;
    }
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getAttendanceRecordsService(schoolId, payload) {
    const requestInfo = getRequestInfo(SEARCH_ATTENDANCE_RECORDS);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}

export function deleteAttendanceRecordsService(schoolId, attendanceId) {
    const requestInfo = getRequestInfo(DELETE_ATTENDANCE_RECORDS_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{id}', attendanceId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}

export function editAttendanceRecordsService(schoolId, payload) {
    const requestInfo = getRequestInfo(EDIT_ATTENDANCE_RECORDS_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}
