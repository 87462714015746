import {
    SET_SUBJECTS_BY_SECTIONS,
    SET_STUDENTS_FOR_ADD_ATTENDANCE,
} from "../../actions/educational";

const initialState = {
    sections: [],
    students: [],
};

const attendance = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_SUBJECTS_BY_SECTIONS:
            newState.sections = action.subjectsBySections;
            break;
        case SET_STUDENTS_FOR_ADD_ATTENDANCE:
            newState.students = action.studentsList;
            break;
        default:
            break;
    }
    return newState;
}

export default attendance;