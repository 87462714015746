import { connect } from 'react-redux';
import App from './App';
import { pageStartUp } from './actions';

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		pageStartUp: () => dispatch(pageStartUp()),
	};
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;