import {
	getUserDetailsService,
	logoutService,
} from '../services/UserProfile';
import {doesErrorExists} from "../utils/validations";
import {put} from "redux-saga/effects";
import {setAppStateLoading, setUserProfile} from "../actions";
import {formatUserResponse} from "../businessLogic/UserProfile";
import {redirectLogin} from "../utils/utils";

export function* getUserDetails({ callback, ignoreUserAuth }) {
	yield put(setAppStateLoading(true));
	const userResponse = yield getUserDetailsService();
	let apiSuccess;
	if (!doesErrorExists(userResponse, ignoreUserAuth)) {
		yield put(setUserProfile(formatUserResponse(userResponse.response)));
		apiSuccess = true;
	} else {
		apiSuccess = false;
	}

	if (callback) {
		callback(apiSuccess);
	}
	yield put(setAppStateLoading(false));
	return apiSuccess;
}

export function* logoutUser() {
	yield logoutService();
	redirectLogin();
}