import {
	getRequestInfo,
	getRequestInfoWithoutContentType,
	callHandleServiceRequest
} from './ServiceHandler';

import {
	LOGOUT_URL,
	GET_USER_DETAILS,
	GET_METHOD,
} from '../constants/urls';

export function logoutService() {
	const requestInfo = getRequestInfoWithoutContentType(LOGOUT_URL);
	return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getUserDetailsService() {
	const requestInfo = getRequestInfo(GET_USER_DETAILS);
	return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}