import { takeEvery, put, select } from 'redux-saga/effects';
import {
	LOGOUT_USER,
	PAGE_START,
	GET_USER_PROFILE,
	ADD_ORGANIZATION,
	GET_MESSAGES,
	JOIN_ORGANIZATION,
	FETCH_SELECTED_ORGANIZATION_DETAILS,
	FETCH_SELECTED_FEATURE_DETAILS,
	FETCH_SELECTED_SUB_FEATURE_DETAILS,
	setAppStateLoading,
} from '../actions';

import {
	ADD_ADMISSION_FORM,
	ADD_DIVISION,
	DELETE_DIVISION,
	ADD_DIVISION_VALUE,
	ADD_ENTITY,
	ADD_SUBJECT,
	DELETE_DIVISION_VALUE,
	DELETE_ENTITY,
	ADD_OR_EDIT_FACULTY,
	ADD_OR_EDIT_STUDENT,
	GET_FACULTY_DETAILS_BY_ID,
	ASSIGN_ENTITY_TO_SUBJECT,
	ASSIGN_FACULTY_TO_SUBJECT,
	GET_STUDENTS_BY_MULTIPLE_ENTITIES,
	ASSIGN_STUDENTS_TO_SUBJECT,
	GET_ZOOM_MEETING_LIST,
	CREATE_ZOOM_MEETING,
	DELETE_STUDENT,
	DELETE_FACULTY,
	CANCEL_ZOOM_MEETING,
	CREATE_MEETING,
	GET_MEETING_LIST,
	CANCEL_MEETING,
	UPDATE_MEETING_URL,
	GET_MEETING_DETAILS_BY_ID,
	GET_STUDENTS_FOR_ADD_ATTENDANCE,
	ADD_ATTENDANCE,
	GET_STUDENT_AUTO_COMPLETE_ATTENDANCE,
	GET_ATTENDANCE_RECORDS,
	DELETE_ATTENDANCE_RECORDS,
	EDIT_ATTENDANCE_RECORDS,
	GET_STUDENTS_FOR_ADD_MARKS,
	ADD_MARKS,
	GET_STUDENT_AUTO_COMPLETE_MARKS,
	GET_MARKS_RECORDS,
	DELETE_MARKS_RECORDS,
	EDIT_MARKS_RECORDS,
	ADD_EXAM,
	GET_EXAM_RECORDS,
	DELETE_EXAM_RECORDS,
	EDIT_EXAM_RECORDS,
} from "../actions/educational";

import {
	logoutUser,
	getUserDetails,
} from './UserProfile';

import {
	addOrganization,
	joinOrganization,
	getOrganizationsAndCategories,
	fetchSelectedOrganizationDetails,
	fetchSelectedFeatureDetails,
	fetchSelectedSubFeatureDetails,
} from './Organizations';

import {
	addDivision,
	deleteDivision,
	addDivisionValue,
	deleteDivisionValue,
	addEntity,
	deleteEntity,
	addAdmissionForm,
} from './Educational/School';

import {
	addOrEditFaculty,
	deleteFaculty,
	addOrEditStudent,
	deleteStudent,
	getFacultyDetailsByIdApi,
} from './Educational/Members';

import {
	addSubject,
	assignEntityToSubject,
	assignFacultyToSubject,
	assignStudentsToSubject,
	getStudentsByMultipleEntities,
} from './Educational/Subjects';

import {
	getStudentsBySectionAndSubjectAttendance,
	addAttendance,
	getStudentAutoCompleteAttendance,
	getAttendanceRecords,
	deleteAttendanceRecords,
	editAttendanceRecords,
} from './Educational/Attendance';

import {
	getStudentsBySectionAndSubjectMarks,
	addMarks,
	getStudentAutoCompleteMarks,
	getMarksRecords,
	deleteMarksRecords,
	editMarksRecords,
} from './Educational/Marks';

import {
	addExam,
	getExamRecords,
	editExamRecords,
	deleteExamRecords,
} from './Educational/Exam';

import {
	getZoomMeetingList,
	createZoomMeeting,
	createMeeting,
	cancelZoomMeeting,
	getMeetingList,
	cancelMeeting,
	updateMeetingLink,
	getMeetingDetailsById,
} from './Educational/OnlineTutorial';

import {getQueryStringParams, isFeatureEnabled, redirectLogin} from "../utils/utils";
import {ROUTE_PATH} from "../constants";
import {MEMBERS_MODE, FEATURES} from "../constants/Educational";

function* startPageSetup() {
	yield put(setAppStateLoading(true));
	const apiSuccess = yield getUserDetails({ ignoreUserAuth: true });
	if(!apiSuccess) {
		redirectLogin();
	}

	yield getOrganizationsAndCategories();
	const queryParams = getQueryStringParams();
	const { featureId, subFeatureId, id, categoryType, entityId} = queryParams;
	if(id) {
		yield fetchSelectedOrganizationDetails({ organizationId:id, categoryType });
	}

	const { isAdminUser } = yield select(state => state.school);
	if(featureId && (isFeatureEnabled(isAdminUser, FEATURES[featureId]))){
		yield fetchSelectedFeatureDetails({featureId});
		if(subFeatureId) {
			let selectedSubFeatureId = subFeatureId;
			if(subFeatureId === MEMBERS_MODE.STUDENT_LIST && !entityId) {
				selectedSubFeatureId = MEMBERS_MODE.STUDENT;
			}
			yield fetchSelectedSubFeatureDetails({subFeatureId: selectedSubFeatureId});
		}
	} else if(featureId){
		window.location.href = ROUTE_PATH.ORGANIZATIONS;
	}

	yield put(setAppStateLoading(false));
}

function* getMessages({ callBack }) {
	yield put(setAppStateLoading(true));
	const messages = [
		{ name: 'Ranjith', content: 'Hi Ammu how are you', gender: 'MALE' },
		{ name: 'Swetha', content: "I'm good. How abt you", gender: 'FEMALE' },
		{ name: 'Ranjith', content: 'Good. Whats up how is life going on in this quarantine', gender: 'MALE' },
		{ name: 'Swetha', content: 'Staying in home little boring, but enjoying with my Food by preparing sweets and yummy snacks', gender: 'FEMALE' },
	];
	callBack(true, messages);
	yield put(setAppStateLoading(true));
}

export default function* rootSaga() {
	yield takeEvery(PAGE_START, startPageSetup)
	yield takeEvery(LOGOUT_USER, logoutUser);
	yield takeEvery(GET_USER_PROFILE, getUserDetails);
	yield takeEvery(GET_MESSAGES, getMessages);
	yield takeEvery(ADD_ORGANIZATION, addOrganization);
	yield takeEvery(JOIN_ORGANIZATION, joinOrganization);
	yield takeEvery(FETCH_SELECTED_ORGANIZATION_DETAILS, fetchSelectedOrganizationDetails);
	yield takeEvery(FETCH_SELECTED_FEATURE_DETAILS, fetchSelectedFeatureDetails);
	yield takeEvery(FETCH_SELECTED_SUB_FEATURE_DETAILS, fetchSelectedSubFeatureDetails);

	// Organization Settings
	yield takeEvery(ADD_DIVISION, addDivision);
	yield takeEvery(DELETE_DIVISION, deleteDivision);
	yield takeEvery(ADD_DIVISION_VALUE, addDivisionValue);
	yield takeEvery(DELETE_DIVISION_VALUE, deleteDivisionValue);
	yield takeEvery(ADD_ENTITY, addEntity);
	yield takeEvery(DELETE_ENTITY, deleteEntity);

	// Organization Admission
	yield takeEvery(ADD_OR_EDIT_FACULTY, addOrEditFaculty);
	yield takeEvery(DELETE_FACULTY, deleteFaculty);
	yield takeEvery(GET_FACULTY_DETAILS_BY_ID, getFacultyDetailsByIdApi);
	yield takeEvery(ADD_OR_EDIT_STUDENT, addOrEditStudent);
	yield takeEvery(DELETE_STUDENT, deleteStudent);
	yield takeEvery(ADD_ADMISSION_FORM, addAdmissionForm);

	// Organization Subjects
	yield takeEvery(ADD_SUBJECT, addSubject);
	yield takeEvery(ASSIGN_ENTITY_TO_SUBJECT, assignEntityToSubject)
	yield takeEvery(ASSIGN_FACULTY_TO_SUBJECT, assignFacultyToSubject);
	yield takeEvery(ASSIGN_STUDENTS_TO_SUBJECT, assignStudentsToSubject);
	yield takeEvery(GET_STUDENTS_BY_MULTIPLE_ENTITIES, getStudentsByMultipleEntities);

	// Online Tutorial
	yield takeEvery(GET_ZOOM_MEETING_LIST, getZoomMeetingList);
	yield takeEvery(CREATE_ZOOM_MEETING, createZoomMeeting);
	yield takeEvery(CANCEL_ZOOM_MEETING, cancelZoomMeeting);

	yield takeEvery(GET_MEETING_LIST, getMeetingList);
	yield takeEvery(CREATE_MEETING, createMeeting);
	yield takeEvery(CANCEL_MEETING, cancelMeeting);
	yield takeEvery(UPDATE_MEETING_URL, updateMeetingLink);
	yield takeEvery(GET_MEETING_DETAILS_BY_ID, getMeetingDetailsById);

	// Organization Attendance
	yield takeEvery(GET_STUDENTS_FOR_ADD_ATTENDANCE, getStudentsBySectionAndSubjectAttendance);
	yield takeEvery(ADD_ATTENDANCE, addAttendance);
	yield takeEvery(GET_STUDENT_AUTO_COMPLETE_ATTENDANCE, getStudentAutoCompleteAttendance);
	yield takeEvery(GET_ATTENDANCE_RECORDS, getAttendanceRecords);
	yield takeEvery(DELETE_ATTENDANCE_RECORDS, deleteAttendanceRecords);
	yield takeEvery(EDIT_ATTENDANCE_RECORDS, editAttendanceRecords);

	// Organization Marks
	yield takeEvery(GET_STUDENTS_FOR_ADD_MARKS, getStudentsBySectionAndSubjectMarks);
	yield takeEvery(ADD_MARKS, addMarks);
	yield takeEvery(GET_STUDENT_AUTO_COMPLETE_MARKS, getStudentAutoCompleteMarks);
	yield takeEvery(GET_MARKS_RECORDS, getMarksRecords);
	yield takeEvery(DELETE_MARKS_RECORDS, deleteMarksRecords);
	yield takeEvery(EDIT_MARKS_RECORDS, editMarksRecords);

	yield takeEvery(ADD_EXAM, addExam);
	yield takeEvery(GET_EXAM_RECORDS, getExamRecords);
	yield takeEvery(DELETE_EXAM_RECORDS, deleteExamRecords);
	yield takeEvery(EDIT_EXAM_RECORDS, editExamRecords);
}