import {CATEGORY_TYPE, T_USER_ID} from "../constants";
import {MOBILE_DISABLED_FEATURES} from "../constants/Educational";
import {LOCAL_INSTIGAR_URL, PROD_INSTIGAR_URL} from "../constants/urls";
import {instigarDecode} from "./decoder";

export const getQueryStringParams = () => {
	const url = window.location.href;
	const query = url.split("?")[1];
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const buildRedirectUrl = (routePath, params, removeParams) => {
	let queryParams = getQueryStringParams();
	queryParams = {...queryParams, ...params};
	let urlParamConstructed = '';

	if (removeParams) {
		Object.keys(removeParams).forEach(key => {
			delete queryParams[key];
		});
	}
	Object.keys(queryParams).forEach((param, i) => {
		if (i === 0){
			urlParamConstructed = `?${param}=${queryParams[param]}`;
		} else {
			urlParamConstructed = `${urlParamConstructed}&${param}=${queryParams[param]}`;
		}
	})
	return routePath+urlParamConstructed;
}

export const setFeatureIdWithRoute = (history, fetchSelectedFeatureDetails, featureId, routePath) => {
	const params = {};
	const removeParams = {};

	fetchSelectedFeatureDetails(featureId);
	params.featureId = featureId;
	removeParams.subFeatureId = true;

	history.push(buildRedirectUrl(routePath, params, removeParams));
}

export const setSubFeatureIdWithRoute = (history, fetchSelectedSubFeatureDetails, subFeatureId, routePath, additionalParams) => {
	let params = {};
	const removeParams = {};
	params.subFeatureId = subFeatureId;
	if (additionalParams) {
		params = { ...params, ...additionalParams};
	}

	history.push(buildRedirectUrl(routePath, params, removeParams));
	fetchSelectedSubFeatureDetails(subFeatureId);
}

export const handleRouteNavigation = (e, props) => {
	const { history, fetchSelectedFeatureDetails, fetchSelectedSubFeatureDetails, toggleMenuBar } = props;
	const { routePath, featureId, subFeatureId } = e.currentTarget.dataset;
	if(toggleMenuBar) {
		toggleMenuBar(e, true);
	}
	const params = {};
	const removeParams = {};

	// if(!subFeatureId) {
	// 	fetchSelectedFeatureDetails(featureId);
	// }

	if(featureId) {
		params.featureId = featureId;
	} else if (!subFeatureId) {
		removeParams.featureId = featureId;
	}

	if(subFeatureId) {
		params.subFeatureId = subFeatureId;
	} else {
		removeParams.subFeatureId = subFeatureId;
	}

	history.push(buildRedirectUrl(routePath, params, removeParams));

	if(!subFeatureId) {
		fetchSelectedFeatureDetails(featureId);
	}

	fetchSelectedSubFeatureDetails(subFeatureId);
}

const isMobile = {
	Android: () => {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: () => {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: () => {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: () => {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: () => {
		return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
	},
	any: () => {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
};

export const isItMobileDevice = () => {
	return !!isMobile.any();
}

export const convertDateToString = (date) => {
	if (!date) {
		return '';
	}
	const d = new Date(date);
	return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`;
}

export const convertStringToDate = (stringDate) => {
	return stringDate ? new Date(stringDate) : null;
}

export const convertJsonToFormData = (jsonObj) => {
	const form_data = new FormData();
	Object.keys(jsonObj).forEach(key => {
		form_data.append(key, jsonObj[key]);
	});
	return form_data;
}

export const convertJsonToRequestParam = (jsonObj) => {
	let form_data = '';
	Object.keys(jsonObj).forEach(key => {
		form_data +=`${key}=${jsonObj[key]}&`;
	});
	return form_data;
}

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const convertDateFormat = (utcSeconds) => {
	if (!utcSeconds) {
		return '';
	}
	const d = new Date(utcSeconds);
	return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

export const convertDateTimeFormat = (utcSeconds) => {
	if (!utcSeconds) {
		return '';
	}
	const d = new Date(utcSeconds);
	const hours = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
	const minutes = d.getMinutes().toString().length === 1 ? `0${d.getMinutes()}` : d.getMinutes();
	return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} ${hours}:${minutes}`;
}

export const convertDateFormatFromString = (stringDate) => {
	const d = convertStringToDate(stringDate);
	if (!d) {
		return '';
	}
	return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

export const formatMeetingDateTime = (stringDate, stringTime) => {
	const scheduledDate = new Date(stringDate);
	let month = scheduledDate.getMonth()+1;
	month = month.toString().length === 1 ? `0${month}` : month;

	let dateNumber = scheduledDate.getDate();
	dateNumber = dateNumber.toString().length === 1 ? `0${dateNumber}` : dateNumber;

	let timeSecs = `${stringTime}:00`;
	if (stringTime === '23:59') {
		timeSecs = `${stringTime}:59`
	}
	return `${scheduledDate.getFullYear()}-${month}-${dateNumber}T${timeSecs}Z`;
}

export const checkValidState = (fieldValues, errors, optionalFields = []) => {
	let isValid = true;
	Object.keys(fieldValues).forEach(key => {
		if ((!fieldValues[key] || errors[key]) && !optionalFields.includes(key)) {
			isValid = false;
			errors[key] = true;
		}
	});
	return isValid;
}

export const resetFieldValues = (fieldValues, errors) => {
	Object.keys(fieldValues).forEach(key => {
		fieldValues[key] = '';
	});

	if(errors) {
		Object.keys(errors).forEach(key => {
			errors[key] = '';
		});
	}
	return { fieldValues, errors };
}

export const anyDivisionValueExists = (divisions) => {
	let hasDivisionValues;
	if (divisions) {
		hasDivisionValues = divisions.filter(division => {
			return division.divisionValues && division.divisionValues.length > 0
		}).length > 0;
	} else {
		hasDivisionValues = true;
	}
	return hasDivisionValues;
}

export const getFeaturesBasedOnCategory = (state) => {
	let features = [];
	if(state.appState.categoryType === CATEGORY_TYPE.EDUCATIONAL.VALUE) {
		features = state.school.features;
	}
	return features;
}

export const getSelectedEntityDetails = (entities, selectedEntityId) => {
	const selectedEntityDetails = entities.filter(entity => entity.id === selectedEntityId).map(entity => {
		const { id, name } = entity;
		return {
			id,
			name,
		}
	});
	return selectedEntityDetails[0];
}

export const isFeatureEnabled = (isAdminUser, feature) => {
	let featureEnabled = false;

	if (isAdminUser || !feature.adminOnly) {
		featureEnabled = true;
	}
	if (window.isMobile && MOBILE_DISABLED_FEATURES.includes(feature.id)) {
		featureEnabled = false;
	}
	return featureEnabled;
}

export const isLocalHostDevelopment = () => {
	const {hostname} = window.location;
	return hostname === 'localhost';
}

function setCookie(cName, cValue, exDays) {
	const d = new Date();
	d.setTime(d.getTime() + (exDays*24*60*60*1000));
	const expires = "expires="+ d.toUTCString();
	let domain = ".instigar.in";
	if (isLocalHostDevelopment()) {
		domain = "localhost";
	}
	document.cookie = cName + "=" + cValue + ";" + expires + ";path=/; domain="+domain+";";
}

function getCookie(cname) {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

export const getSessionKeyValue = (key) => {
	return instigarDecode(getCookie(key));
}

export const redirectLogin = () => {
	let redirectUrl = `${window.location.protocol}${PROD_INSTIGAR_URL}`;
	if (isLocalHostDevelopment()) {
		redirectUrl = LOCAL_INSTIGAR_URL
	}
	setCookie(T_USER_ID, '', 1);
	window.location.href = `${redirectUrl}?redirectUrl=${encodeURIComponent(window.location.href)}`;
}

export const appendPaginationQueryParams = (url, payload) => {
	let updatedUrl = url;
	if (typeof payload === 'object') {
		Object.keys(payload).forEach((paramName, i) => {
			if (i > 0 || updatedUrl.includes("?")) {
				updatedUrl = `${updatedUrl}&${paramName}=${payload[paramName]}`;
			} else {
				updatedUrl = `${updatedUrl}?${paramName}=${payload[paramName]}`;
			}
		})
	}
	return updatedUrl;
}

export const isSelectedTimeValid = (formattedTime) => {
	if (!formattedTime) {
		return false;
	}
	const selectedTime = formattedTime.split(":");
	const dateTime = new Date();
	return parseInt(selectedTime[0]) > dateTime.getHours() || (parseInt(selectedTime[0]) === dateTime.getHours() && parseInt(selectedTime[1]) > dateTime.getMinutes());
}

export const isSelectedFutureDate = (dateSelected) => {
	if (!dateSelected) {
		return false;
	}
	const currentDate = new Date();
	const formattedDate = new Date(dateSelected)
	return currentDate.getFullYear() >= formattedDate.getFullYear() && currentDate.getMonth() >= formattedDate.getMonth()
		&& currentDate.getDate() < formattedDate.getDate();
}