import {
	SET_CATEGORY_TYPES,
	SET_LOADING,
	SET_ORGANIZATIONS_LIST,
	SET_SELECTED_FEATURE_ID,
	SET_SELECTED_ORGANIZATION_ID,
	SET_SELECTED_SUB_FEATURE_ID,
	TOGGLE_ORGANIZATION_LOADER,
} from '../actions';

const initialState = {
	organizationsList: [],
	categories: [],
	error: false,
	errorMessage: null,
	loading: false,
	selectedOrganizationId: null,
	categoryType: null,
	selectedFeatureId: null,
	selectedSubFeatureId: null,
};

const appState = (state = initialState,action)=>{
	let newState = {...state};
	switch(action.type) {
		case SET_LOADING:
			newState.loading = action.loading;
			break;
		case TOGGLE_ORGANIZATION_LOADER:
			newState.loader = !newState.loader;
			break;
		case SET_SELECTED_ORGANIZATION_ID:
			newState.selectedOrganizationId = action.organizationId;
			newState.categoryType = action.categoryType;
			break;
		case SET_SELECTED_FEATURE_ID:
			newState.selectedFeatureId = action.featureId;
			break;
		case SET_CATEGORY_TYPES:
			newState.categories = action.categories;
			break;
		case SET_SELECTED_SUB_FEATURE_ID:
			newState.selectedSubFeatureId = action.subFeatureId;
			break;
		case SET_ORGANIZATIONS_LIST:
			newState.organizationsList = action.organizationsList;
			break;
		default:
			break;
	}
	return newState;
};

export default appState;