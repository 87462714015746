import {put, select} from "redux-saga/effects";
import {setAppStateLoading} from "../../actions";
import {
    getAllSubjectsBySectionsService,
    addExamService,
    getExamRecordsService,
    deleteExamRecordsService,
    editExamRecordsService
} from "../../services/Educational/Exam";

import {doesErrorExists} from "../../utils/validations";
import {
    formatSubjectsBySections,
    formatExamRecordsResponse,
} from "../../businessLogic/Educational/Exam";
import {setSubjectsBySectionsData} from "../../actions/educational";



export function* getAllSubjectsBySectionsForExam(selectedOrganizationId) {
    yield put(setAppStateLoading(true));
    const sectionSubjectsReceived = yield getAllSubjectsBySectionsService(selectedOrganizationId);
    if (!doesErrorExists(sectionSubjectsReceived)) {
        const subjects = formatSubjectsBySections(sectionSubjectsReceived.response);
        yield put(setSubjectsBySectionsData(subjects));
    }
    yield put(setAppStateLoading(false));
}

export function* addExam({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { appState } = yield select(state => state);
    const { selectedOrganizationId } = appState;
    const examResponse = yield addExamService(selectedOrganizationId, payload);
    if (!doesErrorExists(examResponse)) {
    }
    callback();
    yield put(setAppStateLoading(false));
}

export function* getExamRecords({ payload, callBack }) {
    const { selectedOrganizationId } = yield select(state => state.appState);
    const examRecordsResp = yield getExamRecordsService(selectedOrganizationId, payload);
    if (!doesErrorExists(examRecordsResp)) {
        const MarksDetails = formatExamRecordsResponse(examRecordsResp.response);
        callBack(MarksDetails);
    } else {
         callBack({ ExamRecords: [] });
    }
}

export function* deleteExamRecords({ examId, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield deleteExamRecordsService(selectedOrganizationId, examId);
    callback();
    yield put(setAppStateLoading(false));
}

export function* editExamRecords({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield editExamRecordsService(selectedOrganizationId, payload);
    callback();
    yield put(setAppStateLoading(false));
}