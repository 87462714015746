import {callHandleServiceRequest, getRequestInfo} from "./ServiceHandler";
import {
    GET_CATEGORY_TYPES_URL,
    GET_METHOD,
    GET_ORGANIZATIONS_LIST_URL,
    POST_METHOD,
    ADD_ORGANIZATION_URL,
    JOIN_ORGANIZATION_URL,
} from "../constants/urls";

export function getCategoryTypesService() {
    const requestInfo = getRequestInfo(GET_CATEGORY_TYPES_URL);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getOrganizationsListService () {
    const requestInfo = getRequestInfo(GET_ORGANIZATIONS_LIST_URL);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function addOrganizationService(name, categoryId) {
    const requestInfo = getRequestInfo(ADD_ORGANIZATION_URL);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify({categoryId, name}));
}

export function joinOrganizationService(id) {
    const requestInfo = getRequestInfo(JOIN_ORGANIZATION_URL);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify({id}));
}