import {put, select} from "redux-saga/effects";

import {doesErrorExists, isApiStatusValid} from "../../utils/validations";
import {
    addSubjectService,
    getAllSubjectsService,
    assignEntityToSubjectService,
    assignFacultyToSubjectService,
    assignStudentsToSubjectService,
    getStudentsByMultipleEntitiesService,
} from "../../services/Educational/Subjects";
import {formatStudentsByMultipleEntitiesList, formatSubjectsResponse} from "../../businessLogic/Educational/subjects";
import {setStudentsListByMultipleEntities, setSubjectsData} from "../../actions/educational";
import {setAppStateLoading} from "../../actions";

export function* addSubject({subjectDetails, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const subjectAdded = yield addSubjectService(subjectDetails, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(subjectAdded)) {
        yield getAllSubjects();
        apiSuccess = true;
    } else {
        errorMessage = subjectAdded.error ? subjectAdded.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* assignEntityToSubject({subjectId, entityIds, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const entityAssigned = yield assignEntityToSubjectService(subjectId, entityIds, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(entityAssigned)) {
        yield getAllSubjects();
        apiSuccess = true;
    } else {
        errorMessage = entityAssigned.error ? entityAssigned.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* assignFacultyToSubject({subjectId, facultyIds, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const facultyAssigned = yield assignFacultyToSubjectService(subjectId, facultyIds, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(facultyAssigned)) {
        yield getAllSubjects();
        apiSuccess = true;
    } else {
        errorMessage = facultyAssigned.error ? facultyAssigned.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* assignStudentsToSubject({subjectId, studentIds, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const studentsAssigned = yield assignStudentsToSubjectService(subjectId, studentIds, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(studentsAssigned)) {
        yield getAllSubjects();
        apiSuccess = true;
    } else {
        errorMessage = studentsAssigned.error ? studentsAssigned.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* getStudentsByMultipleEntities({ entities, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const studentsResponse = yield getStudentsByMultipleEntitiesService(entities, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(studentsResponse)) {
        yield put(setStudentsListByMultipleEntities(formatStudentsByMultipleEntitiesList(studentsResponse.response)));
        apiSuccess = true;
    } else {
        errorMessage = studentsResponse.error ? studentsResponse.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* getAllSubjects() {
    yield put(setAppStateLoading(true));
    const { schoolId, entities } = yield select(state => state.school);
    const subjectReceived = yield getAllSubjectsService(schoolId);
    if (!doesErrorExists(subjectReceived)) {
        // TODO :: Passing entities as not recieving the entityName from response
        const subjects = formatSubjectsResponse(subjectReceived.response, entities);
        yield put(setSubjectsData(subjects));
    }
    yield put(setAppStateLoading(false));
}