import {put, select} from "redux-saga/effects";
import {setAppStateLoading} from "../../actions";
import {
    getAllSubjectsBySectionsService,
    getStudentsBySectionAndSubjectService,
    addAttendanceService,
    getStudentAutoCompleteService,
    getAttendanceRecordsService,
    deleteAttendanceRecordsService,
    editAttendanceRecordsService,
} from "../../services/Educational/Attendance";

import {doesErrorExists} from "../../utils/validations";
import {
    formatSubjectsBySections,
    formatStudentsForAddAttendance,
    formatStudentsForAutoComplete,
    formatAttendanceRecordsResponse,
} from "../../businessLogic/Educational/Attendance";
import {setSubjectsBySectionsData, setStudentsForAddAttendance} from "../../actions/educational";

export function* getAllSubjectsBySections(selectedOrganizationId) {
    yield put(setAppStateLoading(true));
    const sectionSubjectsReceived = yield getAllSubjectsBySectionsService(selectedOrganizationId);
    if (!doesErrorExists(sectionSubjectsReceived)) {
        const subjects = formatSubjectsBySections(sectionSubjectsReceived.response);
        yield put(setSubjectsBySectionsData(subjects));
    }
    yield put(setAppStateLoading(false));
}

export function* getStudentsBySectionAndSubjectAttendance({ sectionId, subjectId, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    const studentsResponse = yield getStudentsBySectionAndSubjectService(selectedOrganizationId, sectionId, subjectId);
    if (!doesErrorExists(studentsResponse)) {
        const studentsList = formatStudentsForAddAttendance(studentsResponse.response);
        yield put(setStudentsForAddAttendance(studentsList));
    }
    callback();
    yield put(setAppStateLoading(false));
}

export function* addAttendance({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { appState, userProfile } = yield select(state => state);
    const { selectedOrganizationId } = appState;
    const { userId } = userProfile;
    payload.facultyId = userId;
    const attendanceResponse = yield addAttendanceService(selectedOrganizationId, payload);
    if (!doesErrorExists(attendanceResponse)) {
        // TODO :: Passing entities as not recieving the entityName from response
//         const studentsList = formatStudentsForAddAttendance(studentsResponse.response);
//         yield put(setStudentsForAddAttendance(studentsList));

    }
     callback();
    yield put(setAppStateLoading(false));
}

export function* getStudentAutoCompleteAttendance({ studentName, sectionId, callBack }) {
    const { selectedOrganizationId } = yield select(state => state.appState);
    const studentsResponse = yield getStudentAutoCompleteService(selectedOrganizationId, studentName, sectionId);
    if (!doesErrorExists(studentsResponse)) {
        const studentsList = formatStudentsForAutoComplete(studentsResponse.response);
        callBack(studentsList);
    } else {
         callBack([]);
    }
}

export function* getAttendanceRecords({ payload, callBack }) {
    const { selectedOrganizationId } = yield select(state => state.appState);
    const attendanceRecordsResp = yield getAttendanceRecordsService(selectedOrganizationId, payload);
    if (!doesErrorExists(attendanceRecordsResp)) {
        const attendanceDetails = formatAttendanceRecordsResponse(attendanceRecordsResp.response);
        callBack(attendanceDetails);
    } else {
         callBack({ attendanceRecords: [] });
    }
}

export function* deleteAttendanceRecords({ attendanceId, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield deleteAttendanceRecordsService(selectedOrganizationId, attendanceId);
    callback();
    yield put(setAppStateLoading(false));
}

export function* editAttendanceRecords({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield editAttendanceRecordsService(selectedOrganizationId, payload);
    callback();
    yield put(setAppStateLoading(false));
}