import { combineReducers } from 'redux';
import appState from './appState';
import userProfile from './userProfile';
import school from './Educational/school';
import members from './Educational/members';
import onlineTutorial from './Educational/onlineTutorial';
import attendance from './Educational/Attendance';
import marks from './Educational/Marks';
import exam from './Educational/Exam';

export default combineReducers({
  appState,
  userProfile,
  school,
  members,
  onlineTutorial,
  attendance,
  marks,
  exam,
});