export const PAGE_START = "PAGE_START";

export const SET_LOADING = "SET_LOADING";

export const LOGOUT_USER = "LOGOUT_USER";

export const GET_USER_PROFILE = "GET_USER_PROFILE";

export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const TOGGLE_ORGANIZATION_LOADER = "TOGGLE_ORGANIZATION_LOADER";

export const SET_SELECTED_ORGANIZATION_ID = "SET_SELECTED_ORGANIZATION_ID";

export const FETCH_SELECTED_ORGANIZATION_DETAILS = "FETCH_SELECTED_ORGANIZATION_DETAILS";

export const SET_SELECTED_FEATURE_ID = "SET_SELECTED_FEATURE_ID";

export const FETCH_SELECTED_FEATURE_DETAILS = "FETCH_SELECTED_FEATURE_DETAILS";

export const SET_CATEGORY_TYPES = "SET_CATEGORY_TYPES";

export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";

export const ADD_ORGANIZATION = "ADD_ORGANIZATION";

export const JOIN_ORGANIZATION = "JOIN_ORGANIZATION";

export const SET_SELECTED_SUB_FEATURE_ID = "SET_SELECTED_SUB_FEATURE_ID";

export const FETCH_SELECTED_SUB_FEATURE_DETAILS = "FETCH_SELECTED_SUB_FEATURE_DETAILS";

// export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_MESSAGES = "GET_MESSAGES";

export function pageStartUp() {
	return {
		type: PAGE_START,
	}
}

export function setAppStateLoading(loading) {
	return {
		type: SET_LOADING,
		loading,
	}
}

export function logoutUser() {
	return {
		type: LOGOUT_USER,
	}
}

export function setUserProfile(userProfile) {
	return {
		type: SET_USER_PROFILE,
		userProfile,
	}
}

export function getUserDetails(callback) {
	return {
		type: GET_USER_PROFILE,
		callback,
	}
}

export function setSelectedOrganizationId(organizationId, categoryType) {
	return {
		type: SET_SELECTED_ORGANIZATION_ID,
		organizationId,
		categoryType,
	}
}

export function fetchSelectedOrganizationDetails(organizationId, categoryType) {
	return {
		type: FETCH_SELECTED_ORGANIZATION_DETAILS,
		organizationId,
		categoryType,
	}
}

export function setSelectedFeatureId(featureId) {
	return {
		type: SET_SELECTED_FEATURE_ID,
		featureId,
	}
}

export function fetchSelectedFeatureDetails(featureId) {
	return {
		type: FETCH_SELECTED_FEATURE_DETAILS,
		featureId,
	}
}

export function setCategoryTypes(categories) {
	return {
		type: SET_CATEGORY_TYPES,
		categories,
	}
}

export function setOrganizationList(organizationsList) {
	return {
		type: SET_ORGANIZATIONS_LIST,
		organizationsList,
	}
}

export function addOrganization(name, categoryId, callBack) {
	return {
		type: ADD_ORGANIZATION,
		name,
		categoryId,
		callBack,
	}
}

export function joinOrganization(id, callBack) {
	return {
		type: JOIN_ORGANIZATION,
		id,
		callBack,
	}
}

export function setSelectedSubFeatureId(subFeatureId) {
	return {
		type: SET_SELECTED_SUB_FEATURE_ID,
		subFeatureId,
	}
}

export function fetchSelectedSubFeatureDetails(subFeatureId) {
	return {
		type: FETCH_SELECTED_SUB_FEATURE_DETAILS,
		subFeatureId,
	}
}

// export function getNotifications(callBack) {
// 	return {
// 		type: GET_NOTIFICATIONS,
// 		callBack,
// 	}
// }

export function getMessages(callBack) {
	return {
		type: GET_MESSAGES,
		callBack,
	}
}