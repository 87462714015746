import {put, select} from "redux-saga/effects";
import {
    setAppStateLoading,
    setCategoryTypes,
    setOrganizationList,
    setSelectedFeatureId,
    setSelectedOrganizationId,
    setSelectedSubFeatureId
} from "../actions";
import {
    formatCategoriesResponse,
    formatOrganizationListResponse
} from "../businessLogic/Organizations";
import {
    addOrganizationService,
    joinOrganizationService,
    getCategoryTypesService,
    getOrganizationsListService
} from "../services/Organizations";
import {doesErrorExists} from "../utils/validations";
import {CATEGORY_TYPE} from "../constants";
import {MEMBERS_MODE, EDUCATIONAL_FEATURE_NAMES, ONLINE_TUTORIAL_MODE} from "../constants/Educational";

import {getDivisionsData, getSchoolDetails} from "./Educational/School";
import {getAllSubjects} from "./Educational/Subjects";
import {getAllSubjectsBySections} from "./Educational/Attendance";
import {getAllExamsAndSubjectsBySections} from "./Educational/Marks";
import {getAllSubjectsBySectionsForExam} from "./Educational/Exam";

import {
    getFacultyByOrganizationId,
    getFacultyDetailsByIdApi,
    getStudentListByOrganizationId,
    getStudentDetailsByIdApi,
} from "./Educational/Members";
import {setSelectedEntityIdForMembers} from "../actions/educational";
import {getQueryStringParams} from "../utils/utils";

import {getMeetingList, getZoomMeetingList} from "./Educational/OnlineTutorial";

export function* getCategoryTypes() {
    yield put(setAppStateLoading(true));
    const categories = yield getCategoryTypesService();
    if (!doesErrorExists(categories)) {
        yield put(setCategoryTypes(formatCategoriesResponse(categories.response)));
    }
    yield put(setAppStateLoading(false));
}

export function* getOrganizationsList() {
    yield put(setAppStateLoading(true));
    const organizationsList = yield getOrganizationsListService();
    if (!doesErrorExists(organizationsList)) {
        const categories = yield select(state => state.appState.categories);
        yield put(setOrganizationList(formatOrganizationListResponse(organizationsList.response, categories)));
    }
    yield put(setAppStateLoading(false));
}

export function* addOrganization({ name, categoryId, callBack }) {
    yield put(setAppStateLoading(true));
    const addOrganization = yield addOrganizationService(name, categoryId, callBack);
    if (!doesErrorExists(addOrganization)) {
        yield getOrganizationsAndCategories();
        callBack(true);
    } else {
        callBack(false);
    }
    yield put(setAppStateLoading(false));
}

export function* joinOrganization({ id, callBack }) {
    yield put(setAppStateLoading(true));
    const joinOrganizationResponse = yield joinOrganizationService(id);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(joinOrganizationResponse)) {
        yield getOrganizationsAndCategories();
        apiSuccess = true;
    } else {
        errorMessage = joinOrganizationResponse.error ? joinOrganizationResponse.error.errorMessage : null;
    }
    callBack(apiSuccess, errorMessage);
    yield put(setAppStateLoading(false));
}

export function* getOrganizationsAndCategories() {
    yield put(setAppStateLoading(true));
    yield getCategoryTypes();
    yield getOrganizationsList();
    yield put(setAppStateLoading(false));
}


export function* fetchSelectedOrganizationDetails ({organizationId, categoryType}) {
    yield put(setAppStateLoading(true));
    yield put(setSelectedOrganizationId(organizationId, categoryType));
    if(categoryType === CATEGORY_TYPE.EDUCATIONAL.VALUE) {
        yield getSchoolDetails(organizationId);
    }
    yield put(setAppStateLoading(false));
}

export function* fetchSelectedFeatureDetails({ featureId}) {
    yield put(setAppStateLoading(true));
    const { categoryType, selectedOrganizationId } = yield select(state => state.appState);
    yield put(setSelectedFeatureId(featureId));
    if (categoryType === CATEGORY_TYPE.EDUCATIONAL.VALUE) {
        switch(featureId) {
            case EDUCATIONAL_FEATURE_NAMES.ORGANIZATION_SETTINGS:
                yield getDivisionsData(selectedOrganizationId);
                break;
            case EDUCATIONAL_FEATURE_NAMES.MEMBERS:
                yield getDivisionsData(selectedOrganizationId);
                break;
            case EDUCATIONAL_FEATURE_NAMES.SUBJECTS:
                yield getDivisionsData(selectedOrganizationId);
                yield getFacultyByOrganizationId(selectedOrganizationId);
                yield getAllSubjects();
                break;
            case EDUCATIONAL_FEATURE_NAMES.ONLINE_TUTORIAL:
                yield getDivisionsData(selectedOrganizationId);
                break;
            case EDUCATIONAL_FEATURE_NAMES.ATTENDANCE:
                yield getAllSubjectsBySections(selectedOrganizationId);
                break;
            case EDUCATIONAL_FEATURE_NAMES.MARKS:
                yield getAllExamsAndSubjectsBySections(selectedOrganizationId);
                break;
            case EDUCATIONAL_FEATURE_NAMES.EXAM:
                yield getAllSubjectsBySectionsForExam(selectedOrganizationId);
                break;
            default:
                // do nothing
                break;
        }
    }
    yield put(setAppStateLoading(false));
}

export function* fetchSelectedSubFeatureDetails({ subFeatureId }) {
    yield put(setAppStateLoading(true));
    const { categoryType, selectedOrganizationId } = yield select(state => state.appState);
    const queryParams = getQueryStringParams();
    const { entityId } = queryParams
    if(entityId) {
        yield put(setSelectedEntityIdForMembers(entityId));
    }
    if (categoryType === CATEGORY_TYPE.EDUCATIONAL.VALUE) {
        switch(subFeatureId) {
            case MEMBERS_MODE.FACULTY:
                yield getFacultyByOrganizationId(selectedOrganizationId);
                break;
            case MEMBERS_MODE.EDIT_FACULTY:
                const { facultyId } = queryParams;
                yield getFacultyDetailsByIdApi({ facultyId });
                break;
            case MEMBERS_MODE.STUDENT:
                yield getDivisionsData(selectedOrganizationId);
                break;
            case MEMBERS_MODE.STUDENT_LIST:
                yield put(setSelectedEntityIdForMembers(entityId));
                yield getStudentListByOrganizationId();
                break;
            case MEMBERS_MODE.EDIT_STUDENT:
                const { studentId } = queryParams;
                yield getStudentDetailsByIdApi({ studentId });
                break;
            case ONLINE_TUTORIAL_MODE.ZOOM:
                yield getAllSubjects();
                yield getZoomMeetingList({});
                break;
            case ONLINE_TUTORIAL_MODE.GOOGLE_MEET:
                yield getAllSubjects();
                yield getMeetingList({ meetingType: ONLINE_TUTORIAL_MODE.GOOGLE_MEET });
                break;
            case ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM:
                yield getAllSubjects();
                yield getMeetingList({ meetingType: ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM });
                break;
            default:
                // do nothing
                break;
        }
    }
    yield put(setSelectedSubFeatureId(subFeatureId));
    yield put(setAppStateLoading(false));
}