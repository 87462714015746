import { convertDateTimeFormat } from "../../utils/utils";

const formatSubjects = (subjectsList) => {
    let subjects = [];
    if(subjectsList) {
        subjects = subjectsList.map(subject => {
            return {
                id: subject.id,
                name: subject.name,
            }
        })
    }
    return subjects;
}

const formatExams = (examsList) => {
    let exams = [];
    if(examsList) {
        exams = examsList.map(exam => {
            return {
                id: exam.id,
                name: exam.name,
            }
        })
    }
    return exams;
}

export const formatExamsAndSubjectsBySections = (response) => {
    let examsAndSubjectsBySections = {};
    if(response) {
        response.forEach(sectionSubjectsAndExams => {
            const { section, exams, subjects,  } = sectionSubjectsAndExams;
            const { id, sectionStr } = section
            examsAndSubjectsBySections[id] = {
                id,
                name: sectionStr,
                subjects: formatSubjects(subjects),
                exams: formatExams(exams)
            }
        })
    }
    return examsAndSubjectsBySections;
}

export const formatStudentsForAddMarks = (response) => {
    let studentsList = [];
    if(response) {
       studentsList = response.map(student => {
           return {
               id: student.id,
               firstName: student.firstName,
               lastName: student.lastName,
               rollNo: student.rollNo,
           }
       })
    }
    return studentsList;
}

export const formatStudentsForAutoComplete = (response) => {
    let studentsList = [];
    if(response) {
       studentsList = response.map(student => {
           return {
               id: student.id,
               name: `${student.firstName} ${student.lastName}`,
           }
       })
    }
    return studentsList;
}

export const formatMarksRecordsResponse = (response) => {
    let marksList = [];
    const { pageNo, data, pageSize, total } = response;
    if(data.length) {
       marksList = data.map(marks => {
           const { subject, section, startDate, endDate, marksRecords, id } = marks;
           return {
                id,
                subjectId: subject.id,
                subjectName: subject.name,
                sectionId: section.id,
                sectionName: section.sectionStr,
                startTime: convertDateTimeFormat(startDate),
                endTime: convertDateTimeFormat(endDate),
                records: marksRecords.map(record => {
                            const { student, grade } = record;
                            const { firstName, lastName, rollNo } = student;
                            return {
                                  id: student.id,
                                  firstName,
                                  lastName,
                                  grade,
                                  rollNo,
                              }
                        }),
            }
       })
    }
    return {
        pageNo,
        pageSize,
        total,
        marksList
    }
}