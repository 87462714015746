export const formatUserResponse = (response) => {
    const { firstName, lastName, email, phone, admin, username, id, gender } = response;
    return {
        firstName,
        lastName,
        admin,
        email,
        phone,
        username,
        gender,
        userId: id,
    }
}