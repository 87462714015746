export const PROD_INSTIGAR_URL = "//login.instigar.in";
export const LOCAL_INSTIGAR_URL = "http://localhost:3001";

export const LOCAL_DOMAIN = "http://localhost:8081";
export const PROD_DOMAIN = "//api.instigar.in";

export const ZOOM_LOCAL_DOMAIN = "http://localhost:3002";
export const ZOOM_PROD_DOMAIN = "//zoom.instigar.in";

export const API_REQUEST_TIMEOUT = 5000;

export const POST_METHOD = "POST";
export const GET_METHOD = "GET";
export const DELETE_METHOD = "DELETE";

export const LOGOUT_URL = "/logout";

export const GET_USER_DETAILS = "/api/v1/user/details";
export const GET_CATEGORY_TYPES_URL = "/api/v1/category";
export const GET_ORGANIZATIONS_LIST_URL = "/api/v1/organisation/all";
export const ADD_ORGANIZATION_URL = "/api/v1/organisation/add";
export const JOIN_ORGANIZATION_URL = "/api/v1/organisation/join";

// Educational Organization Settings
export const GET_SCHOOL_DETAILS_URL = "/api/v1/organisation/byId";

export const GET_DIVISIONS_DATA_URL = "/api/v1/organisation/{organisationId}/section/all";
export const ADD_DIVISION_URL = "/api/v1/organisation/{organisationId}/sectionTypeKey/add";
export const DELETE_DIVISION_URL = "/api/v1/organisation/{organisationId}/section/deleteDivision";
export const ADD_DIVISION_VALUE_URL = "/api/v1/organisation/{organisationId}/sectionTypeValue/add";
export const DELETE_DIVISION_VALUE_URL = "/api/v1/organisation/{organisationId}/section/deleteDivisionValue";

export const ADD_ENTITY_URL = "/api/v1/organisation/{organisationId}/section/addEntity";
export const DELETE_ENTITY_URL = "/api/v1/organisation/{organisationId}/section/deleteEntity";

export const ADD_ADMISSION_FORM_URL = "/api/v1/organisation/";

// Educational Members
export const ADD_FACULTY_URL = "/api/v1/organisation/{organisationId}/faculty/addFaculty";
export const DELETE_FACULTY_URL = "/api/v1/organisation/{organisationId}/faculty/deleteFaculty"
export const EDIT_FACULTY_URL = "/api/v1/organisation/{organisationId}/faculty/editFaculty"
export const GET_FACULTY_BY_ORGANIZATION_URL = "/api/v1/organisation/{organisationId}/faculty/all";
export const GET_FACULTY_DETAILS_BY_ID_URL = "/api/v1/organisation/{organisationId}/faculty/byId";

export const GET_STUDENT_BY_ORGANIZATION_URL = "/api/v1/organisation/{organisationId}/student/findBySection";
export const GET_STUDENT_DETAILS_BY_ID_URL = "/api/v1/organisation/{organisationId}/student/byId"
export const ADD_STUDENT_URL = "/api/v1/organisation/{organisationId}/student/addStudent";
export const DELETE_STUDENT_URL = "/api/v1/organisation/{organisationId}/student/deleteStudent"
export const EDIT_STUDENT_URL = "/api/v1/organisation/{organisationId}/student/editStudent";

// Educational Attendance.js Marks.js
export const GET_STUDENTS_BY_SECTION_AND_SUBJECT = '/api/v1/organisation/{organisationId}/subject/{subjectId}/section/{sectionId}/students';
export const GET_STUDENTS_AUTO_COMPLETE = '/api/v1/organisation/{organisationId}/student/search';


// Educational Attendance.js
export const ADD_ATTENDANCE = '/api/v1/organisation/{organisationId}/attendance/add';
export const SEARCH_ATTENDANCE_RECORDS = '/api/v1/organisation/{organisationId}/attendance/search';
export const DELETE_ATTENDANCE_RECORDS_URL = '/api/v1/organisation/{organisationId}/attendance/{id}/delete';
export const EDIT_ATTENDANCE_RECORDS_URL = '/api/v1/organisation/{organisationId}/attendance/edit';
export const GET_ALL_SUBJECTS_SECTIONS = '/api/v1/organisation/{organisationId}/attendance/subjectsAndSections';

// Educational Marks.js
export const ADD_MARKS = '/api/v1/organisation/{organisationId}/marks/add';
export const SEARCH_MARKS_RECORDS = '/api/v1/organisation/{organisationId}/marks/search';
export const DELETE_MARKS_RECORDS_URL = '/api/v1/organisation/{organisationId}/marks/{id}/delete';
export const EDIT_MARKS_RECORDS_URL = '/api/v1/organisation/{organisationId}/marks/edit';
export const GET_ALL_EXAMS_SUBJECTS_SECTIONS = '/api/v1/organisation/{organisationId}/marks/subjectsAndExams';


// Educational Exam.js
export const ADD_EXAM = '/api/v1/organisation/{organisationId}/exam/add';
export const SEARCH_EXAM_RECORDS = '/api/v1/organisation/{organisationId}/exam/search';
export const DELETE_EXAM_RECORDS_URL = '/api/v1/organisation/{organisationId}/exam/{id}/delete';
export const EDIT_EXAM_RECORDS_URL = '/api/v1/organisation/{organisationId}/exam/edit';


// Educational Subject
export const ADD_SUBJECT_URL = "/api/v1/organisation/{organisationId}/subject/add";
export const ASSIGN_ENTITY_TO_SUBJECT_URL = "/api/v1/organisation/{organisationId}/subject/addSections";
export const ASSIGN_FACULTY_TO_SUBJECT_URL = "/api/v1/organisation/{organisationId}/subject/addFaculties";
export const ASSIGN_STUDENTS_TO_SUBJECT_URL = "/api/v1/organisation/{organisationId}/subject/addStudents";
export const GET_STUDENTS_BY_MULTIPLE_ENTITIES_URL = "/api/v1/organisation/{organisationId}/student/findBySections";
export const GET_ALL_SUBJECT_URL = "/api/v1/organisation/{organisationId}/subject/all";

// Educational Online Tutorial
export const GET_ZOOM_MEETINGS_BY_ORGANIZATION_ID_URL = "/api/v1/organisation/{organisationId}/zoomMeeting/list";
export const GET_ZOOM_MEETINGS_BY_FACULTY_ID = "/api/v1/organisation/{organisationId}/zoomMeeting/faculty/{facultyId}/list";
export const GET_ZOOM_MEETINGS_BY_STUDENT_ID = "/api/v1/organisation/{organisationId}/zoomMeeting/student/{studentId}/list"
export const CREATE_ZOOM_MEETING_URL = "/api/v1/organisation/{organisationId}/zoomMeeting/addMeeting";
export const CANCEL_ZOOM_MEETING_URL = "/api/v1/organisation/{organisationId}/zoomMeeting/meeting/{meetingId}/deleteMeeting";

 // Google Meet Meeting
export const GET_GOOGLE_MEET_MEETINGS_BY_ORGANIZATION_ID_URL = "/api/v1/organisation/{organisationId}/googleMeeting/list";
export const GET_GOOGLE_MEET_MEETINGS_BY_FACULTY_ID_URL = "/api/v1/organisation/{organisationId}/googleMeeting/faculty/{facultyId}/list";
export const GET_GOOGLE_MEET_MEETINGS_BY_STUDENT_ID_URL = "/api/v1/organisation/{organisationId}/googleMeeting/student/{studentId}/list";
export const GET_GOOGLE_MEET_DETAIL_BY_ID_URL = "/api/v1/organisation/{organisationId}/googleMeeting/meeting/{meetingId}";
export const CREATE_GOOGLE_MEET_MEETING_URL = "/api/v1/organisation/{organisationId}/googleMeeting/addMeeting";
export const UPDATE_GOOGLE_MEET_MEETING_URL = "/api/v1/organisation/{organisationId}/googleMeeting/update"
export const CANCEL_GOOGLE_MEET_MEETING_URL = "/api/v1/organisation/{organisationId}/googleMeeting/meeting/{meetingId}/deleteMeeting";

// Microsoft Team Meeting
export const GET_MICROSOFT_TEAM_MEETINGS_BY_ORGANIZATION_ID_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/list";
export const GET_MICROSOFT_TEAM_MEETINGS_BY_FACULTY_ID_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/faculty/{facultyId}/list";
export const GET_MICROSOFT_TEAM_MEETINGS_BY_STUDENT_ID_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/student/{studentId}/list";
export const GET_MICROSOFT_TEAM_DETAIL_BY_ID_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/meeting/{meetingId}";
export const CREATE_MICROSOFT_TEAM_MEETING_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/addMeeting";
export const UPDATE_MICROSOFT_TEAM_MEETING_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/update"
export const CANCEL_MICROSOFT_TEAM_MEETING_URL = "/api/v1/organisation/{organisationId}/microsoftMeeting/meeting/{meetingId}/deleteMeeting";

export const NEW_GOOGLE_MEET_URL = "//meet.google.com/new";
export const GOOGLE_MEET_DOMAIN = "//meet.google.com";

export const NEW_MICROSOFT_TEAM_URL = "//teams.microsoft.com/";
export const MICROSOFT_TEAM_DOMAIN = "//teams.microsoft.com";