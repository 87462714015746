import {
    SET_DIVISIONS_AND_ENTITY_DATA,
    SET_SCHOOL_DETAILS,
    SET_STUDENTS_LIST_BY_MULTIPLE_ENTITIES,
    SET_SUBJECTS_DATA
} from "../../actions/educational";

const initialState = {
    divisions: [],
    schoolId: null,
    schoolName: null,
    features: [],
    entities: [],
    subjects: [],
    studentsList: [],

};

const school = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_SCHOOL_DETAILS:
            newState.schoolId = action.school.id;
            newState.schoolName = action.school.name;
            newState.isAdminUser = action.school.isAdminUser;
            newState.isFacultyMember = action.school.isFacultyMember;
            newState.features = action.school.features;
            newState.memberId = action.school.memberId;
            break;
        case SET_DIVISIONS_AND_ENTITY_DATA:
            newState.divisions = action.divisions;
            newState.entities = action.entities;
            // newState.entities = action.entities;
            break;
        case SET_SUBJECTS_DATA:
            newState.subjects = action.subjects;
            break;
        case SET_STUDENTS_LIST_BY_MULTIPLE_ENTITIES:
            newState.studentsList = action.studentsList;
            break;
        default:
            break;
    }
    return newState;
}

export default school;