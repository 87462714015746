import {
    SET_SUBJECTS_BY_SECTIONS
} from "../../actions/educational";

const initialState = {
    sections: []
};

const exam = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_SUBJECTS_BY_SECTIONS:
            newState.sections = action.examsAndSubjectsBySections;
            break;
        default:
            break;
    }
    return newState;
}

export default exam;