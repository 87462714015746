import {
    SET_GOOGLE_MEET_MEETING_LIST,
    SET_ZOOM_MEETING_LIST,
} from "../../actions/educational";

const initialState = {
    meetingsList: [],
};

const onlineTutorial = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_ZOOM_MEETING_LIST:
            if (action.scheduledZoomMeetings) {
                newState.scheduledZoomMeetings = action.scheduledZoomMeetings;
            }

            if (action.completedZoomMeetings) {
                newState.completedZoomMeetings = action.completedZoomMeetings;
            }
            break;
        case SET_GOOGLE_MEET_MEETING_LIST:
            if(action.scheduledMeetings) {
                newState.scheduledMeetings = action.scheduledMeetings;
            }

            if (action.completedMeetings) {
                newState.completedMeetings = action.completedMeetings;
            }
            break;
        default:
            break;
    }
    return newState;
}

export default onlineTutorial;
