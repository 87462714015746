import {put, select} from "redux-saga/effects";

import {doesErrorExists, isApiStatusValid} from "../../utils/validations";
import {formatZoomMeetingListResponse, formatMeetingListResponse} from "../../businessLogic/Educational/onlineTutorial";
import {setZoomMeetingList, setMeetingList} from "../../actions/educational";

import {
    getZoomMeetingsByOrganizationIdService,
    getZoomMeetingsByFacultyIdService,
    getZoomMeetingsByStudentIdService,
    getMeetingsByOrganizationIdService,
    getMeetingsByFacultyIdService,
    getMeetingsByStudentIdService,
    createZoomMeetingService,
    cancelZoomMeetingService,
    cancelMeetingService,
    createMeetingService,
    updateMeetingService,
    getMeetingDetailsByIdService,
} from "../../services/Educational/OnlineTutorial";
import {setAppStateLoading} from "../../actions";
import {DEFAULT_PAGE_SIZE} from "../../constants";
import {ONLINE_TUTORIAL_MODE} from "../../constants/Educational";

export function* getZoomMeetingList({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const {schoolId, isAdminUser, isFacultyMember, memberId } = yield select(state => state.school);

    let meetingsListResponse;
    if (isAdminUser) {
        meetingsListResponse = yield getZoomMeetingsByOrganizationIdService(payload, schoolId);
    } else if (isFacultyMember) {
        meetingsListResponse = yield getZoomMeetingsByFacultyIdService(payload, schoolId, memberId);
    } else {
        meetingsListResponse = yield getZoomMeetingsByStudentIdService(payload, schoolId, memberId);
    }
    if (meetingsListResponse && !doesErrorExists(meetingsListResponse)) {
        const meetings = formatZoomMeetingListResponse(meetingsListResponse.response);
        if (payload && payload.completed) {
            yield put(setZoomMeetingList({ completedZoomMeetings: meetings }));
        } else {
            yield put(setZoomMeetingList({ scheduledZoomMeetings: meetings }));
        }

    }
    if(callback) {
        callback();
    }
    yield put(setAppStateLoading(false));
}

export function* getMeetingList({ payload, meetingType, callback }) {
    yield put(setAppStateLoading(true));
    const {schoolId, isAdminUser, isFacultyMember, memberId } = yield select(state => state.school);

    if(!payload) {
        payload = {
            pageSize: DEFAULT_PAGE_SIZE,
        };
    } else if (!payload.pageSize) {
        payload.pageSize = DEFAULT_PAGE_SIZE;
    }

    let googleMeetingsListResponse;
    if (isAdminUser) {
        googleMeetingsListResponse = yield getMeetingsByOrganizationIdService(payload, meetingType, schoolId);
    } else if (isFacultyMember) {
        googleMeetingsListResponse = yield getMeetingsByFacultyIdService(payload, meetingType, schoolId, memberId);
    } else {
        googleMeetingsListResponse = yield getMeetingsByStudentIdService(payload, meetingType, schoolId, memberId);
    }
    if (googleMeetingsListResponse && !doesErrorExists(googleMeetingsListResponse)) {
        const meetings = formatMeetingListResponse(googleMeetingsListResponse.response, meetingType);
        if (payload.completed) {
            yield put(setMeetingList({ completedMeetings: meetings }));
        } else {
            yield put(setMeetingList({ scheduledMeetings: meetings }));
        }
    } else {
        yield put(setMeetingList({ completedMeetings: [],  scheduledMeetings: [] }));
    }
    if(callback) {
        callback();
    }
    yield put(setAppStateLoading(false));
}

export function* createZoomMeeting({ meetingDetails, isInstantMeeting, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingCreated = yield createZoomMeetingService(schoolId, meetingDetails, isInstantMeeting);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(meetingCreated)) {
        yield getZoomMeetingList({});
        apiSuccess = true;
    } else {
        errorMessage = meetingCreated.error
            ? ( meetingCreated.error.errorMessage || meetingCreated.responseText )
            : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* createMeeting({ meetingDetails, isInstantMeeting, meetingType, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingCreated = yield createMeetingService(schoolId, meetingDetails, meetingType, isInstantMeeting);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(meetingCreated)) {
        yield getMeetingList({ meetingType });
        apiSuccess = true;
    } else {
        errorMessage = meetingCreated.error
            ? (meetingCreated.error.errorMessage || meetingCreated.error.responseText )
            : null

    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* cancelZoomMeeting({ meetingNumber, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingCanceled = yield cancelZoomMeetingService(schoolId, meetingNumber);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(meetingCanceled)) {
        yield getZoomMeetingList({});
        apiSuccess = true;
    } else {
        errorMessage = meetingCanceled.error ? meetingCanceled.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* cancelMeeting({ meetingNumber, meetingType, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingCanceled = yield cancelMeetingService(schoolId, meetingType, meetingNumber);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(meetingCanceled)) {
        yield getMeetingList({ meetingType });
        apiSuccess = true;
    } else {
        errorMessage = meetingCanceled.error ? meetingCanceled.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* updateMeetingLink({meetingNumber, meetingLink, meetingType, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingUpdated = yield updateMeetingService(schoolId, meetingNumber, meetingLink, meetingType);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(meetingUpdated)) {
        yield getMeetingList({ meetingType });
        apiSuccess = true;
    } else {
        errorMessage = meetingUpdated.error ? meetingUpdated.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* getMeetingDetailsById({ meetingNumber, meetingType, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const meetingDetails = yield getMeetingDetailsByIdService(schoolId, meetingType, meetingNumber);
    let meetingLink;
    if (isApiStatusValid(meetingDetails)) {
        if(meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
            meetingLink = meetingDetails.response.meetLink;
        } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
            meetingLink = meetingDetails.response.meetLink;
        }
    }
    callback(meetingLink)
    yield put(setAppStateLoading(false));
}