import React, { Component } from 'react';

import {isItMobileDevice} from './utils/utils';
import Index from './components/Index';
import ErrorBoundary from "./ErrorBoundary";

class App extends Component {
  constructor(props) {
    super(props);
    window.isMobile = isItMobileDevice();
    const { pageStartUp } = this.props;
    pageStartUp();
  }	

  render() {
    const { props } = this;
  	return (
  	    <ErrorBoundary>
            <div className="App">
                <Index {...props}/>
            </div>
        </ErrorBoundary>
	  );
  }
}

export default App;
