const formatSubjectStudents = (studentList) => {
    let formattedStudentList = [];
    if(studentList) {
        formattedStudentList = studentList.map(student => {
            return {
                id: student.id,
                name: `${student.firstName} ${student.lastName?student.lastName:''}`
            }
        })
    }
    return formattedStudentList;
};

const formatSubjectEntities = (entities, originalEntities) => {
    let formattedEntities = [];
    if(entities) {
        formattedEntities = originalEntities.filter(originalEntity => {
            return entities.filter(entity => entity.id === originalEntity.id).length > 0
        }).map(entity => {
            return {
                id: entity.id,
                name: entity.name,
            }
        })
    }
    return formattedEntities;
}

const formatFacultyList = (faculties) => {
    let formattedFaculties = [];
    if(faculties) {
        formattedFaculties = faculties.map(faculty => {
            return {
                id: faculty.id,
                name: `${faculty.firstName} ${faculty.lastName?faculty.lastName:''}`
            }
        })
    }
    return formattedFaculties;
}

export const formatSubjectsResponse = (response, originalEntities) => {
    let subjectsList = [];
    if(response) {
        subjectsList = response.map(subject => {
            return {
                id: subject.id,
                name: subject.name,
                type: subject.subjectType,
                faculties: formatFacultyList(subject.faculties),
                entities: formatSubjectEntities(subject.sections, originalEntities),
                students: formatSubjectStudents(subject.students),
            }
        })
    }
    return subjectsList
}

export const formatStudentsByMultipleEntitiesList = (response) => {
    let studentsList = [];
    if (response) {
        studentsList = response.map(student => {
            return {
                id: student.id,
                name: `${student.firstName} ${student.lastName?student.lastName:''}`,
            }
        })
    }
    return studentsList;
}