import { LOCAL_DOMAIN, PROD_DOMAIN, API_REQUEST_TIMEOUT } from '../constants/urls';
import {getSessionKeyValue, isLocalHostDevelopment} from "../utils/utils";
import {T_USER_ID} from "../constants";

export function getRequestInfo(urlPath, ignoreContentType = false) {
	const url = isLocalHostDevelopment() ? `${LOCAL_DOMAIN}${urlPath}` : `${window.location.protocol}${PROD_DOMAIN}${urlPath}`;
	const headers = {
		'Accept': 'application/json; charset=utf-8',
	};
	if (!ignoreContentType) {
		headers['Content-Type'] = 'application/json; charset=utf-8';
	}
	return {
		url,
		headers,
	};
}

export function getRequestInfoWithoutContentType(urlPath) {
	const url = isLocalHostDevelopment() ? `${LOCAL_DOMAIN}${urlPath}` : `${window.location.protocol}${PROD_DOMAIN}${urlPath}`;
	const headers = {};
	return {
		url,
		headers,
	};
}

export function getHandleServiceRequest(promiseFunction) {
	return new Promise((resolve, reject) => {
		return promiseFunction(resolve, reject);
	})
		.then((response) => {
			return {
				response: response || true,
			}
		})
		.catch((error) => {
			return {
				error,
			}
		})
}

export function callHandleServiceRequest( requestInfo, methodType, bodyParams) {
	const options = {
		method: methodType,
		headers: requestInfo.headers,
		timeout: API_REQUEST_TIMEOUT,
		cache: 'no-cache',
		credentials: 'include',
	}

	if (methodType !== 'GET' && methodType !== 'HEAD') {
		options.body = bodyParams;
	}
	const t_user_id = getSessionKeyValue(T_USER_ID);
	if (t_user_id) {
		options.headers.Authorization = `Bearer ${t_user_id}`;
	}

	return getHandleServiceRequest((resolve, reject) => {
		fetch(requestInfo.url, options)
			.then((res) => {
				const status = res.status;
				if (status === 409) {
					res.text().then(responseText => {
						reject({ status, errors: true, responseText })
					}).catch((e) => {
						reject({ status, errors: true })
					})
				} else {
					res.json()
						.then((json) => {
							json.status = status;
							resolve(json);
						})
						.catch(() => {
							reject({ status, errors: true })
						});
				}
			})
			.catch((errors) => {
				reject({errors})
			});
	});
}