import React, {Component, lazy, Suspense} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

import {EDUCATIONAL_PATH} from "../constants/Educational";

import {ORGANIZATION_MODE, ROUTE_PATH} from "../constants";
import Footer from "./Common/Footer";

const ProfileContainer = lazy(() => import("../containers/ProfileContainer"));
const HeaderContainer = lazy(() => import("../containers/HeaderContainer"));
const SideMenuBarContainer = lazy(() => import("../containers/SideMenuBarContainer"));
const OrganizationsContainer = lazy(() => import("../containers/OrganizationsContainer"));
const EducationalContainer = lazy(() => import("../containers/Educational/EducationalContainer"));

class Index extends Component {

	state = {
		windowHeight: parseInt(window.innerHeight)-50,
		expandMenu: true,
	}

	componentDidMount() {
		window.addEventListener("resize", this.adjustWindowHeight);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.adjustWindowHeight);
	}

	adjustWindowHeight = () => {
		this.setState({ windowHeight: parseInt(window.innerHeight)-50 });
	}

	toggleMenuBar = (e, isExpanded) => {
		const expandMenu = typeof isExpanded !== 'undefined' ? isExpanded : !this.state.expandMenu;
		this.setState({expandMenu});
		e.stopPropagation();
	}

	render() {
		const { windowHeight, expandMenu } = this.state;
		return (
			<Suspense fallback={<div className="school-app"></div>}>
				<div className="school-app">
					<Router>
						<div className="home-layout" style={{minHeight: windowHeight }}>
							<SideMenuBarContainer toggleMenuBar={this.toggleMenuBar} expandMenu={expandMenu}/>
							<div className="main schools-section">
								<HeaderContainer toggleMenuBar={this.toggleMenuBar} />
								<div className="page-container" style={{maxHeight: windowHeight }}>
									<div className="left-content">
										<div className="outter-wp">
											<Switch>
												<Route path="/profile">
													<ProfileContainer />
												</Route>
												<Route path={ROUTE_PATH.ORGANIZATIONS}>
													<OrganizationsContainer
														subPath={ORGANIZATION_MODE.SHOW_ORGANIZATIONS}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.SETTINGS}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.ORGANIZATION_SETTINGS}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.MEMBERS}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.MEMBERS}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.SUBJECTS}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.SUBJECTS}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.ONLINE_TUTORIAL}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.ONLINE_TUTORIAL}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.ATTENDANCE}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.ATTENDANCE}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.EXAM}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.EXAM}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.MARKS}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.MARKS}
													/>
												</Route>
												<Route path={ROUTE_PATH.EDUCATIONAL.DASHBOARD}>
													<EducationalContainer
														subPath={EDUCATIONAL_PATH.DASHBOARD}
													/>
												</Route>
												<Route path={ROUTE_PATH.HOME}>
													<OrganizationsContainer
														subPath={ORGANIZATION_MODE.SHOW_ORGANIZATIONS}
													/>
												</Route>
											</Switch>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Footer />
					</Router>
				</div>
			</Suspense>
		);
	}
}

export default Index;