import {convertDateTimeFormat} from "../../utils/utils";
import {ONLINE_TUTORIAL_MODE} from "../../constants/Educational";

export const formatZoomMeetingListResponse = (response) => {
    let meetings = {
        pageNo: 1,
        total: 1,
        list: []
    };
    if(response) {
        meetings.pageNo = response.pageNo;
        meetings.total = response.total;
        response.data.forEach(meeting => {
            const { zoomMeetingResponse, startDate, data, section, faculty, subject } = meeting;

            const { topic, id, agenda, join_url, start_url } = zoomMeetingResponse;
            const { duration } = JSON.parse(data);
            const { sectionStr } = section;
            const { name } = subject;

            const { firstName, lastName } = faculty;

            const formattedMeeting = {
                topic,
                agenda,
                meetingNumber: id,
                dateTime: convertDateTimeFormat(startDate),
                joinUrl: join_url,
                startUrl: start_url,
                entity: sectionStr,
                facultyName: `${firstName} ${lastName?lastName:''}`,
                subjectName: name,
                duration,
            };
            meetings.list.push(formattedMeeting)
        })
    }
    return meetings;
}

export const formatMeetingListResponse = (response, meetingType) => {
    let meetings = {
        pageNo: 1,
        total: 1,
        list: []
    };

    if(response) {
        meetings.pageNo = response.pageNo;
        meetings.total = response.total;
        response.data.forEach(meeting => {
            const { startTime, section, faculty, subject, duration, id, topic, agenda } = meeting;
            const { sectionStr } = section;
            const { name } = subject;
            const { firstName, lastName } = faculty;

            let meetingLink;
            if (meetingType === ONLINE_TUTORIAL_MODE.GOOGLE_MEET) {
                meetingLink = meeting.meetLink;
            } else if (meetingType === ONLINE_TUTORIAL_MODE.MICROSOFT_TEAM) {
                meetingLink = meeting.meetLink;
            }

            const formattedMeeting = {
                meetingNumber: id,
                topic,
                agenda,
                dateTime: convertDateTimeFormat(startTime),
                meetingLink: meetingLink,
                entity: sectionStr,
                facultyName: `${firstName} ${lastName?lastName:''}`,
                subjectName: name,
                duration,
            };

            meetings.list.push(formattedMeeting);
        })
    }
    return meetings;
}