import {redirectLogin} from "./utils";

export const validateEmail = (mailValue) =>  {
 return mailValue === '' || (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailValue));
}

export const validatePhone = (inputValue) => {
	return inputValue === '' || (inputValue.match(/[7-9]{1}[0-9]{9}/) && inputValue.length === 10);
}

export const validateNumber = (inputValue) => {
	return inputValue === '' || !isNaN(inputValue);
}

export const doesErrorExists = (responseObj, ignoreUserAuth = false) => {
	const { response, error } =  responseObj;
	const status = response ? response.status : error.status;
	if(!ignoreUserAuth && (status === 401 || status === 403)) {
		redirectLogin();
	}
	return error || !response || response.errors || response.error || response.status < 200 || response.status >= 300;
}

export const isApiStatusValid = ({ response, error }) => {
	const status = response ? response.status : error.status;
	if(status === 401 || status === 403) {
		redirectLogin();
	}
	return status ? status === 200 : false;
}