export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again!!!";

// Educational Module

export const ADD_DIVISION_SUCCESS = "Your Division got added successfully";
export const ADD_DIVISION_VALUE_SUCCESS = "Your Values got added successfully";
export const ADD_ENTITY_SUCCESS = "Your Entity got added successfully";
export const ADD_DIVISION_FORM_SUCCESS = "Your Admission Form got added successfully";

// Subjects
export const ADD_SUBJECT_SUCCESS = "Your Subject got added successfully";
export const SELECT_AT_LEAST_ONE_STUDENT = "Select at least one student";
export const SELECT_AT_LEAST_ONE_FACULTY = "Select at least one faculty";
export const SELECT_AT_LEAST_ONE_ENTITY = "Select at least one entity";

// Students
export const ADD_OR_EDIT_FACULTY_SUCCESS = "Faculty details saved successfully";
export const ADD_OR_EDIT_STUDENT_SUCCESS = "Student details saved successfully";

// Online Tutorial
export const INVALID_GOOGLE_MEET_URL_ERROR = "Invalid google meet joining url";
export const INVALID_MICROSOFT_TEAM_URL_ERROR = "Invalid microsoft teams joining url";