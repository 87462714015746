import {convertDateFormatFromString} from "../../utils/utils";
import {mergeDeep} from "../../utils/json-operations";

export const formatFacultyFormPayload = (facultyForm, originalFacultyForm) => {
    const {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        bloodGroup,
        medicalIllness,
        phone,
        alternatePhone,
        emailId,
        joiningDate,
        pAddress1,
        pCountry,
        pState,
        pCity,
        pPin,
        lAddress1,
        lCountry,
        lState,
        lCity,
        lPin,

        emergencyFirstName,
        emergencyLastName,
        emergencyPrimaryPhone,
        emergencySecondaryPhone,
        emergencyRelation,
        emergencyEmailId,
        emergencyAddress1,
        emergencyCountry,
        emergencyState,
        emergencyCity,
        emergencyPin,

        qualifications,
        designations,
    } = facultyForm;

    const updatedFacultyForm = {
        firstName,
        lastName,
        gender,
        email: emailId,
        dateOfBirth,
        joiningDate,
        phone,
        alternatePhone,
        bloodGroup,
        medicalIllness,
        qualifications,
        designations,
        localAddress: {
            address: lAddress1,
            city: lCity,
            state: lState,
            country: lCountry,
            zip_code: lPin,
        },
        permanentAddress: {
            address: pAddress1,
            city: pCity,
            state: pState,
            country: pCountry,
            zip_code: pPin,
        },
        emergencyContact: {
            firstName: emergencyFirstName,
            lastName: emergencyLastName,
            relationshipDesc: emergencyRelation,
            email: emergencyEmailId,
            phone: emergencyPrimaryPhone,
            alternatePhone: emergencySecondaryPhone,
            address: {
                address: emergencyAddress1,
                city: emergencyCity,
                state: emergencyState,
                country: emergencyCountry,
                zip_code: emergencyPin,
            }
        },
    }

    if (originalFacultyForm) {
        return  mergeDeep(originalFacultyForm, updatedFacultyForm);
    } else {
        return updatedFacultyForm;
    }
}

export const formatStudentFormPayload = (studentForm, originalStudentForm, selectedEntityId) => {
    const {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        bloodGroup,
        medicalIllness,
        primaryPhone,
        secondaryPhone,
        emailId,
        joiningDate,
        pAddress1,
        pCountry,
        pState,
        pCity,
        pPin,
        lAddress1,
        lCountry,
        lState,
        lCity,
        lPin,

        emergencyFirstName,
        emergencyLastName,
        emergencyPrimaryPhone,
        emergencySecondaryPhone,
        emergencyRelation,
        emergencyEmailId,
        emergencyAddress1,
        emergencyCountry,
        emergencyState,
        emergencyCity,
        emergencyPin,

        fatherFirstName,
        fatherLastName,
        fatherPrimaryPhone,
        fatherSecondaryPhone,
        fatherEmailId,
        fatherEducation,
        fatherProfession,

        motherFirstName,
        motherLastName,
        motherPrimaryPhone,
        motherSecondaryPhone,
        motherEmailId,
        motherEducation,
        motherProfession,
    } = studentForm;

    const updatedStudentForm = {
        firstName,
        lastName,
        gender,
        sectionId: selectedEntityId,
        email: emailId,
        dateOfBirth,
        joiningDate,
        phone: primaryPhone,
        alternatePhone: secondaryPhone,
        bloodGroup,
        medicalIllness,
        localAddress: {
            address: lAddress1,
            city: lCity,
            state: lState,
            country: lCountry,
            zip_code: lPin,
        },
        permanentAddress: {
            address: pAddress1,
            city: pCity,
            state: pState,
            country: pCountry,
            zip_code: pPin,
        },
        emergencyContact: {
            firstName: emergencyFirstName,
            lastName: emergencyLastName,
            relationshipDesc: emergencyRelation,
            email: emergencyEmailId,
            phone: emergencyPrimaryPhone,
            alternatePhone: emergencySecondaryPhone,
            address: {
                address: emergencyAddress1,
                city: emergencyCity,
                state: emergencyState,
                country: emergencyCountry,
                zip_code: emergencyPin,
            }
        },
        father : {
            firstName: fatherFirstName,
            lastName: fatherLastName,
            relationshipDesc: "FATHER",
            email: fatherEmailId,
            phone: fatherPrimaryPhone,
            alternatePhone: fatherSecondaryPhone,
            education: fatherEducation,
            profession: fatherProfession,
        },
        mother : {
            firstName: motherFirstName,
            lastName: motherLastName,
            relationshipDesc: "MOTHER",
            email: motherEmailId,
            phone: motherPrimaryPhone,
            alternatePhone: motherSecondaryPhone,
            education: motherEducation,
            profession: motherProfession,
        },
    }

    if (originalStudentForm) {
        return  mergeDeep(originalStudentForm, updatedStudentForm);
    } else {
        return updatedStudentForm;
    }
}


export const formatFacultyListResponse = (response) => {
    return response.map(faculty => {
        return {
            id: faculty.id,
            name: faculty.lastName ? `${faculty.firstName} ${faculty.lastName}` : faculty.firstName,
            registrationNumber: faculty.registrationNumber,
            designation: faculty.designation,
            joiningDate: convertDateFormatFromString(faculty.joiningDate),
        }
    })
}

export const formatFacultyDetailsResponse = (response) => {
    const {
        firstName,
        lastName,
        gender,
        email,
        dateOfBirth,
        joiningDate,
        phone,
        alternatePhone,
        bloodGroup,
        medicalIllness,
        qualifications,
        designations,

        localAddress,
        permanentAddress,
        emergencyContact,
    } = response;

    return {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        bloodGroup,
        medicalIllness,
        phone,
        alternatePhone,
        emailId: email,
        joiningDate,

        pAddress1: permanentAddress.address,
        pCountry: permanentAddress.country,
        pState: permanentAddress.state,
        pCity: permanentAddress.city,
        pPin: permanentAddress.zip_code,

        lAddress1: localAddress.address,
        lCountry: localAddress.country,
        lState: localAddress.state,
        lCity: localAddress.city,
        lPin: localAddress.zip_code,

        emergencyFirstName: emergencyContact.firstName,
        emergencyLastName: emergencyContact.lastName,
        emergencyPrimaryPhone: emergencyContact.phone,
        emergencySecondaryPhone: emergencyContact.alternatePhone,
        emergencyRelation: emergencyContact.relationshipDesc,
        emergencyEmailId: emergencyContact.email,
        emergencyAddress1: emergencyContact.address ? emergencyContact.address.address : '',
        emergencyCountry: emergencyContact.address ? emergencyContact.address.country : '',
        emergencyState: emergencyContact.address ? emergencyContact.address.state : '',
        emergencyCity: emergencyContact.address ? emergencyContact.address.city : '',
        emergencyPin: emergencyContact.address ? emergencyContact.address.zip_code : '',

        qualifications,
        designations,
    }
}

export const formatStudentListResponse = (response) => {
    return response.map(student => {
        return {
            id: student.id,
            name: `${student.firstName} ${student.lastName}`,
            rollNo: student.rollNo,
            joiningDate: convertDateFormatFromString(student.joiningDate),
        }
    })
}

export const formatStudentDetailsResponse = (response) => {
    const {
        firstName,
        lastName,
        gender,
        email,
        dateOfBirth,
        joiningDate,
        phone,
        alternatePhone,
        bloodGroup,
        medicalIllness,

        localAddress,
        permanentAddress,
        emergencyContact,

        father,
        mother,
    } = response;

    return {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        bloodGroup,
        medicalIllness,
        primaryPhone: phone,
        secondaryPhone: alternatePhone,
        emailId: email,
        joiningDate,

        pAddress1: permanentAddress.address,
        pCountry: permanentAddress.country,
        pState: permanentAddress.state,
        pCity: permanentAddress.city,
        pPin: permanentAddress.zip_code,

        lAddress1: localAddress.address,
        lCountry: localAddress.country,
        lState: localAddress.state,
        lCity: localAddress.city,
        lPin: localAddress.zip_code,

        emergencyFirstName: emergencyContact.firstName,
        emergencyLastName: emergencyContact.lastName,
        emergencyPrimaryPhone: emergencyContact.phone,
        emergencySecondaryPhone: emergencyContact.alternatePhone,
        emergencyRelation: emergencyContact.relationshipDesc,
        emergencyEmailId: emergencyContact.email,
        emergencyAddress1: emergencyContact.address ? emergencyContact.address.address : '',
        emergencyCountry: emergencyContact.address ? emergencyContact.address.country : '',
        emergencyState: emergencyContact.address ? emergencyContact.address.state : '',
        emergencyCity: emergencyContact.address ? emergencyContact.address.city : '',
        emergencyPin: emergencyContact.address ? emergencyContact.address.zip_code : '',

        fatherFirstName: father.firstName,
        fatherLastName: father.lastName,
        fatherPrimaryPhone: father.phone,
        fatherSecondaryPhone: father.alternatePhone,
        fatherEmailId: father.email,
        fatherEducation: father.education,
        fatherProfession: father.profession,

        motherFirstName: mother.firstName,
        motherLastName: mother.lastName,
        motherPrimaryPhone: mother.phone,
        motherSecondaryPhone: mother.alternatePhone,
        motherEmailId: mother.email,
        motherEducation: mother.education,
        motherProfession: mother.profession,
    }
}