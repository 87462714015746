import {select, put} from "redux-saga/effects";
import {
    addOrEditFacultyService,
    deleteFacultyService,
    addOrEditStudentService,
    deleteStudentService,
    getFacultyByOrganizationIdService,
    getFacultyDetailsByIdService,
    getStudentDetailsByIdService,
    getStudentListByOrganizationIdService,
} from "../../services/Educational/Members";
import {doesErrorExists, isApiStatusValid} from "../../utils/validations";
import {
    formatFacultyDetailsResponse,
    formatFacultyFormPayload,
    formatFacultyListResponse,
    formatStudentDetailsResponse,
    formatStudentFormPayload,
    formatStudentListResponse,
} from "../../businessLogic/Educational/admissions";
import {
    setFacultyList,
    setFacultyDetailsById,
    setFacultyApiSuccessMessage,
    setStudentApiSuccessMessage,
    setStudentList,
    setStudentDetailsById
} from "../../actions/educational";
import {setAppStateLoading} from "../../actions";

export function* addOrEditFaculty({facultyForm, callback}) {
    yield put(setAppStateLoading(true));
    const state = yield select(state => state);
    const { school, members } = state;
    const { schoolId } = school;
    const { originalFacultyDetails } = members;
    const facultyAdded = yield addOrEditFacultyService(formatFacultyFormPayload(facultyForm, originalFacultyDetails), schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(facultyAdded)) {
        yield put(setFacultyApiSuccessMessage());
        apiSuccess = true;
    } else {
        errorMessage = facultyAdded.error ? facultyAdded.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* deleteFaculty({ facultyId }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const deletedFaculty = yield deleteFacultyService(schoolId, facultyId);
    if (isApiStatusValid(deletedFaculty)) {
        yield getFacultyByOrganizationId(schoolId);
    }
    yield put(setAppStateLoading(false));
}

export function* getFacultyByOrganizationId(schoolId) {
    yield put(setAppStateLoading(true));
    const facultyList = yield getFacultyByOrganizationIdService(schoolId);
    if (!doesErrorExists(facultyList)) {
        yield put(setFacultyList(formatFacultyListResponse(facultyList.response)));
    }
    yield put(setAppStateLoading(false));
}

export function* getStudentListByOrganizationId() {
    yield put(setAppStateLoading(true));
    const { school, members } = yield select(state => state);
    const { schoolId } = school;
    const { selectedEntityId } = members;
    const studentsList = yield getStudentListByOrganizationIdService(schoolId, selectedEntityId);
    if (!doesErrorExists(studentsList)) {
        yield put(setStudentList(formatStudentListResponse(studentsList.response)));
    }
    yield put(setAppStateLoading(false));
}

export function* getFacultyDetailsByIdApi({ facultyId }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const facultyDetails = yield getFacultyDetailsByIdService(facultyId, schoolId);
    if (!doesErrorExists(facultyDetails)) {
        yield put(setFacultyDetailsById(formatFacultyDetailsResponse(facultyDetails.response), facultyDetails.response));
    }
    yield put(setAppStateLoading(false));
}

export function* getStudentDetailsByIdApi({ studentId }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const facultyDetails = yield getStudentDetailsByIdService(studentId, schoolId);
    if (!doesErrorExists(facultyDetails)) {
        yield put(setStudentDetailsById(formatStudentDetailsResponse(facultyDetails.response), facultyDetails.response));
    }
    yield put(setAppStateLoading(false));
}

export function* addOrEditStudent({ studentForm, callback }) {
    yield put(setAppStateLoading(true));
    const state = yield select(state => state);
    const { school, members } = state;
    const { schoolId } = school;
    const { originalStudentDetails, selectedEntityId } = members;
    const studentAdded = yield addOrEditStudentService(formatStudentFormPayload(studentForm, originalStudentDetails, selectedEntityId), schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(studentAdded)) {
        yield put(setStudentApiSuccessMessage());
        apiSuccess = true;
    } else {
        errorMessage = studentAdded.error ? studentAdded.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* deleteStudent({ studentId }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const deletedStudent = yield deleteStudentService(schoolId, studentId);
    if (isApiStatusValid(deletedStudent)) {
        yield getStudentListByOrganizationId();
    }
    yield put(setAppStateLoading(false));
}

// export function* getStudentListByEntity () {
//     const { schoolId } = yield select(state => state.school);
// }