import {put, select} from "redux-saga/effects";

import {doesErrorExists, isApiStatusValid} from "../../utils/validations";
import {setDivisionsAndEntityData, setSchoolDetails} from "../../actions/educational";

import {formatDivisionsAndEntityResponse, formatSchoolDetailsResponse} from "../../businessLogic/Educational/schoolSettings";
import {
    addDivisionService,
    deleteDivisionService,
    addDivisionValueService,
    deleteDivisionValueService,
    getDivisionsDataService,
    getSchoolDetailsService,
    addEntityService,
    deleteEntityService,
    addAdmissionFormService,
} from "../../services/Educational/School";
import {setAppStateLoading} from "../../actions";

export function* getSchoolDetails(schoolId) {
    yield put(setAppStateLoading(true));
    const schoolDetails = yield getSchoolDetailsService(schoolId);
    if (!doesErrorExists(schoolDetails)) {
        yield put(setSchoolDetails(formatSchoolDetailsResponse(schoolDetails.response)));
    }
    yield put(setAppStateLoading(false));
}

export function* getDivisionsData(schoolId) {
    yield put(setAppStateLoading(true));
    const divisionsAndEntityData = yield getDivisionsDataService(schoolId);
    if (!doesErrorExists(divisionsAndEntityData)) {
        const { divisions, entities } = formatDivisionsAndEntityResponse(divisionsAndEntityData.response);
        yield put(setDivisionsAndEntityData(divisions, entities));
    }
    yield put(setAppStateLoading(false));
}

export function* addDivision({ division, callback }) {
    yield put(setAppStateLoading(true));
    const {schoolId} = yield select(state => state.school);
    const divisionAdded = yield addDivisionService(division, schoolId);
    let apiSuccess = false;
    if (!doesErrorExists(divisionAdded)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    }
    callback(apiSuccess)
    yield put(setAppStateLoading(false));
}

export function* deleteDivision({ divisionId, callback }) {
    yield put(setAppStateLoading(true));
    const {schoolId} = yield select(state => state.school);
    const divisionDeleted = yield deleteDivisionService(divisionId, schoolId);
    let apiSuccess = false;
    if (isApiStatusValid(divisionDeleted)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    }
    callback(apiSuccess)
    yield put(setAppStateLoading(false));
}

export function* addDivisionValue({ divisionValue, selectedDivisionId, callback }) {
    yield put(setAppStateLoading(true));
    const {schoolId } = yield select(state => state.school);
    const divisionValueAdded = yield addDivisionValueService(divisionValue, schoolId, selectedDivisionId);
    let apiSuccess = false;
    if (!doesErrorExists(divisionValueAdded)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    }
    callback(apiSuccess)
    yield put(setAppStateLoading(false));
}

export function* deleteDivisionValue({ divisionValueId, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const divisionValueDeleted = yield deleteDivisionValueService(divisionValueId, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(divisionValueDeleted)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    } else {
        errorMessage = divisionValueDeleted.error ? divisionValueDeleted.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* addEntity({entityFields, callback}) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const entityAdded = yield addEntityService(entityFields, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (!doesErrorExists(entityAdded)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    } else {
        errorMessage = entityAdded.response.message ? entityAdded.response.message : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* deleteEntity({ entityId, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const entityDeleted = yield deleteEntityService(entityId, schoolId);
    let apiSuccess = false;
    let errorMessage;
    if (isApiStatusValid(entityDeleted)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    } else {
        errorMessage = entityDeleted.error ? entityDeleted.error.errorMessage : null;
    }
    callback(apiSuccess, errorMessage)
    yield put(setAppStateLoading(false));
}

export function* addAdmissionForm({ admissionFormData, callback }) {
    yield put(setAppStateLoading(true));
    const { schoolId } = yield select(state => state.school);
    const entityDeleted = yield addAdmissionFormService(admissionFormData, schoolId);
    let apiSuccess = false;
    if (isApiStatusValid(entityDeleted)) {
        yield getDivisionsData(schoolId);
        apiSuccess = true;
    }
    callback(apiSuccess)
    yield put(setAppStateLoading(false));
}