import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";
import {
    POST_METHOD,
    GET_METHOD,
    ADD_STUDENT_URL,
    DELETE_STUDENT_URL,
    EDIT_STUDENT_URL,
    ADD_FACULTY_URL,
    DELETE_FACULTY_URL,
    EDIT_FACULTY_URL,
    GET_FACULTY_BY_ORGANIZATION_URL,
    GET_FACULTY_DETAILS_BY_ID_URL,
    GET_STUDENT_BY_ORGANIZATION_URL,
    GET_STUDENT_DETAILS_BY_ID_URL,
} from "../../constants/urls";

export function addOrEditFacultyService(facultyPayload, schoolId) {
    let requestInfo;
    if(!facultyPayload.id && !facultyPayload.registrationNumber) {
        requestInfo = getRequestInfo(ADD_FACULTY_URL);
    } else {
        requestInfo = getRequestInfo(EDIT_FACULTY_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(facultyPayload));
}

export function deleteFacultyService(schoolId, facultyId) {
    const requestInfo = getRequestInfo(DELETE_FACULTY_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?facultyId=${facultyId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}

export function getFacultyByOrganizationIdService(schoolId) {
    const requestInfo = getRequestInfo(GET_FACULTY_BY_ORGANIZATION_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getStudentListByOrganizationIdService(schoolId, entityId) {
    const requestInfo = getRequestInfo(GET_STUDENT_BY_ORGANIZATION_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?sectionId=${entityId}`;
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getFacultyDetailsByIdService(facultyId, schoolId) {
    const requestInfo = getRequestInfo(GET_FACULTY_DETAILS_BY_ID_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?facultyId=${facultyId}`;
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getStudentDetailsByIdService(studentId, schoolId) {
    const requestInfo = getRequestInfo(GET_STUDENT_DETAILS_BY_ID_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?studentId=${studentId}`;
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function addOrEditStudentService(studentPayload, schoolId) {
    let requestInfo;
    if(!studentPayload.id) {
        requestInfo = getRequestInfo(ADD_STUDENT_URL);
    } else {
        requestInfo = getRequestInfo(EDIT_STUDENT_URL);
    }
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(studentPayload));
}

export function deleteStudentService(schoolId, studentId) {
    const requestInfo = getRequestInfo(DELETE_STUDENT_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?studentId=${studentId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}