import {CATEGORY_TYPE} from "../constants";
import {convertDateFormat} from "../utils/utils";

export const formatCategoriesResponse = (response) => {
    const categories = {};
    response
        .filter(category => category.enabled && CATEGORY_TYPE[category.name])
        .forEach(category => {
            const {id, name} = category;
            categories[id] = {
                id: id,
                type: name,
                label: CATEGORY_TYPE[name]
            }
        });
    return categories;
}

export const formatOrganizationListResponse = (response, categories) => {
    return response.map(organization => {
        const { id, name, categoryId, createdDate, createdBy } = organization;
        return {
            id,
            name,
            categoryType: categories[categoryId] ? categories[categoryId].type : '',
            createdDate: convertDateFormat(createdDate),
            createdBy,
        };
    });
}