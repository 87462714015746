import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";
import {
    GET_METHOD,
    POST_METHOD,
    GET_DIVISIONS_DATA_URL,
    GET_SCHOOL_DETAILS_URL,
    ADD_DIVISION_URL,
    DELETE_DIVISION_URL,
    ADD_DIVISION_VALUE_URL,
    DELETE_DIVISION_VALUE_URL,
    ADD_ENTITY_URL,
    DELETE_ENTITY_URL,
    ADD_ADMISSION_FORM_URL,
} from "../../constants/urls";

export function getSchoolDetailsService(schoolId) {
    const requestInfo = getRequestInfo(GET_SCHOOL_DETAILS_URL);
    requestInfo.url = `${requestInfo.url}?organisationId=${schoolId}`;
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function getDivisionsDataService(schoolId) {
    const requestInfo = getRequestInfo(GET_DIVISIONS_DATA_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function addDivisionService(division, schoolId) {
    const requestInfo = getRequestInfo(ADD_DIVISION_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(division));
}

export function deleteDivisionService(divisionId, schoolId) {
    const requestInfo = getRequestInfo(DELETE_DIVISION_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?sectionTypeKeyId=${divisionId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(divisionId));
}

export function addDivisionValueService(divisionValue, schoolId, divisionId) {
    const requestInfo = getRequestInfo(ADD_DIVISION_VALUE_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?sectionTypeKeyId=${divisionId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(divisionValue));
}

export function deleteDivisionValueService(divisionValueId, schoolId) {
    const requestInfo = getRequestInfo(DELETE_DIVISION_VALUE_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?sectionTypeValueId=${divisionValueId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(divisionValueId));
}

export function addEntityService(entityFields, schoolId) {
    const requestInfo = getRequestInfo(ADD_ENTITY_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    const entityBody = { entity: { } };
    Object.keys(entityFields).forEach(entityId => {
        entityBody.entity[entityId] = entityFields[entityId].value;
    })

    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(entityBody));
}

export function deleteEntityService(entityId, schoolId) {
    const requestInfo = getRequestInfo(DELETE_ENTITY_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = `${requestInfo.url}?sectionId=${entityId}`;
    return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}

export function addAdmissionFormService(admissionFormData, schoolId) {
    const requestInfo = getRequestInfo(ADD_ADMISSION_FORM_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(admissionFormData));
}