import {
    SET_EXAMS_AND_SUBJECTS_BY_SECTIONS,
    SET_STUDENTS_FOR_ADD_MARKS,
} from "../../actions/educational";

const initialState = {
    sections: [],
    students: [],
};

const marks = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_EXAMS_AND_SUBJECTS_BY_SECTIONS:
            newState.sections = action.examsAndSubjectsBySections;
            break;
        case SET_STUDENTS_FOR_ADD_MARKS:
            newState.students = action.studentsList;
            break;
        default:
            break;
    }
    return newState;
}

export default marks;