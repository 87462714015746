const formatSubjects = (subjectsList) => {
    let subjects = [];
    if(subjectsList) {
        subjects = subjectsList.map(subject => {
            return {
                id: subject.id,
                name: subject.name,
            }
        })
    }
    return subjects;
}

export const formatSubjectsBySections = (response) => {
    let subjectsBySections = {};
    if(response) {
        response.filter(sectionSubjects => sectionSubjects.subjects && sectionSubjects.subjects.length).forEach(sectionSubjects => {
            const { subjects, section } = sectionSubjects;
            const { id, sectionStr } = section
            subjectsBySections[id] = {
                id,
                name: sectionStr,
                subjects: formatSubjects(subjects),
            }
        })
    }
    return subjectsBySections;
}

export const formatExamRecordsResponse = (response) => {
    let examList = [];
    const { pageNo, data, pageSize, total } = response;
    if(data.length) {
        examList = data.map(exam => {
           const { subject, section, name, id } = exam;
           return {
                id,
                name,
                subjectId: subject.id,
                subjectName: subject.name,
                sectionId: section.id,
                sectionName: section.sectionStr,
           }
       })
    }
    return {
        pageNo,
        pageSize,
        total,
        examList
    }
}