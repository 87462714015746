import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';

import combineReducers from './reducers/index';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas/App';

import "./css/index.scss";

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const sagaMiddleware = createSagaMiddleware();
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(combineReducers, enhancer );
sagaMiddleware.run(rootSaga);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

let startApp = () => {
    ReactDOM.render(<Provider store={store}><AppContainer /></Provider>, document.getElementById('root'));
}


if(!window.cordova) {
  startApp()
} else {
    document.addEventListener('deviceready', startApp, false);
}


serviceWorker.register();

