import { convertDateTimeFormat } from "../../utils/utils";

const formatSubjects = (subjectsList) => {
    let subjects = [];
    if(subjectsList) {
        subjects = subjectsList.map(subject => {
            return {
                id: subject.id,
                name: subject.name,
            }
        })
    }
    return subjects;
}

export const formatSubjectsBySections = (response) => {
    let subjectsBySections = {};
    if(response) {
        response.filter(sectionSubjects => sectionSubjects.subjects && sectionSubjects.subjects.length).forEach(sectionSubjects => {
            const { subjects, section } = sectionSubjects;
            const { id, sectionStr } = section
            subjectsBySections[id] = {
                id,
                name: sectionStr,
                subjects: formatSubjects(subjects),
            }
        })
    }
    return subjectsBySections;
}

export const formatStudentsForAddAttendance = (response) => {
    let studentsList = [];
    if(response) {
       studentsList = response.map(student => {
           return {
               id: student.id,
               firstName: student.firstName,
               lastName: student.lastName,
               rollNo: student.rollNo,
           }
       })
    }
    return studentsList;
}

export const formatStudentsForAutoComplete = (response) => {
    let studentsList = [];
    if(response) {
       studentsList = response.map(student => {
           return {
               id: student.id,
               name: `${student.firstName} ${student.lastName}`,
           }
       })
    }
    return studentsList;
}

export const formatAttendanceRecordsResponse = (response) => {
    let attendanceList = [];
    const { pageNo, data, pageSize, total } = response;
    if(data.length) {
       attendanceList = data.map(attendance => {
           const { subject, section, startDate, endDate, attendanceRecords, id } = attendance;
           return {
                id,
                subjectId: subject.id,
                subjectName: subject.name,
                sectionId: section.id,
                sectionName: section.sectionStr,
                startTime: convertDateTimeFormat(startDate),
                endTime: convertDateTimeFormat(endDate),
                records: attendanceRecords.map(record => {
                            const { student, present } = record;
                            const { firstName, lastName, rollNo } = student;
                            return {
                                  id: student.id,
                                  firstName,
                                  lastName,
                                  present,
                                  rollNo,
                              }
                        }),
            }
       })
    }
    return {
        pageNo,
        pageSize,
        total,
        attendanceList
    }
}