import {
    SET_FACULTY_DETAILS_BY_ID,
    SET_FACULTY_LIST,
    SET_FACULTY_API_MESSAGE,
    CLEAR_API_MESSAGES,
    SET_SELECTED_ENTITY_ID_FOR_MEMBERS,
    SET_STUDENT_API_MESSAGE, SET_STUDENT_LIST, SET_STUDENT_DETAILS_BY_ID,
} from "../../actions/educational";
import {ADD_OR_EDIT_FACULTY_SUCCESS, ADD_OR_EDIT_STUDENT_SUCCESS} from "../../constants/MessageTokens";

const initialState = {
    facultyList: [],
    facultyDetails: null,
    facultyApiMessage: null,
    originalFacultyDetails: null,

    studentList: [],
    selectedEntity: null,
    studentDetails: null,
    originalStudentDetails: null,
};

const members = (state = initialState, action)=>{
    let newState = {...state};
    switch(action.type) {
        case SET_FACULTY_LIST:
            newState.facultyList = action.facultyList;
            newState.facultyDetails = null;
            newState.originalFacultyDetails = null;
            break;
        case SET_STUDENT_LIST:
            newState.studentList = action.studentList;
            newState.studentDetails = null;
            newState.originalStudentDetails = null;
            break;
        case SET_FACULTY_DETAILS_BY_ID:
            newState.facultyDetails = action.facultyDetails;
            newState.originalFacultyDetails = action.originalFacultyDetails;
            break;
        case SET_STUDENT_DETAILS_BY_ID:
            newState.studentDetails = action.studentDetails;
            newState.originalStudentDetails = action.originalStudentDetails;
            break;
        case SET_SELECTED_ENTITY_ID_FOR_MEMBERS:
            newState.selectedEntityId = action.entityId;
            break;
        case SET_FACULTY_API_MESSAGE:
            newState.facultyApiMessage = ADD_OR_EDIT_FACULTY_SUCCESS;
            break;
        case SET_STUDENT_API_MESSAGE:
            newState.studentApiMessage = ADD_OR_EDIT_STUDENT_SUCCESS;
            break;
        case CLEAR_API_MESSAGES:
            newState.facultyApiMessage = null;
            newState.studentApiMessage = null;
            break;
        default:
            break;
    }
    return newState;
}

export default members;