import {ENCODING_SECRET_KEY} from "../constants";

export const utfDecode = (encodedString) => {
    let string = "";
    let i = 0;
    let c = 0, c2 = 0, c3 = 0;

    while ( i < encodedString.length ) {

        c = encodedString.charCodeAt(i);

        if (c < 128) {
            string += String.fromCharCode(c);
            i++;
        }
        else if((c > 191) && (c < 224)) {
            c2 = encodedString.charCodeAt(i+1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
        }
        else {
            c2 = encodedString.charCodeAt(i+1);
            c3 = encodedString.charCodeAt(i+2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
        }

    }

    return string;
}

export const instigarDecode = (input) => {
    let output = "";
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/\=INSTIGAR]/g, "");

    while (i < input.length) {

        enc1 = ENCODING_SECRET_KEY.indexOf(input.charAt(i++));
        enc2 = ENCODING_SECRET_KEY.indexOf(input.charAt(i++));
        enc3 = ENCODING_SECRET_KEY.indexOf(input.charAt(i++));
        enc4 = ENCODING_SECRET_KEY.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 !== 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
            output = output + String.fromCharCode(chr3);
        }

    }

    output = utfDecode(output);

    return output;
}