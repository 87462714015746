import {put, select} from "redux-saga/effects";
import {setAppStateLoading} from "../../actions";
import {
    getAllExamsAndSubjectsBySectionsService,
    getStudentsBySectionAndSubjectService,
    addMarksService,
    getStudentAutoCompleteService,
    getMarksRecordsService,
    deleteMarksRecordsService,
    editMarksRecordsService,
} from "../../services/Educational/Marks";

import {doesErrorExists} from "../../utils/validations";
import {
    formatExamsAndSubjectsBySections,
    formatStudentsForAddMarks,
    formatStudentsForAutoComplete,
    formatMarksRecordsResponse,
} from "../../businessLogic/Educational/Marks";
import {setExamsAndSubjectsBySectionsData, setStudentsForAddMarks} from "../../actions/educational";

export function* getAllExamsAndSubjectsBySections(selectedOrganizationId) {
    yield put(setAppStateLoading(true));
    const examsAndSubjectsBySectionsReceived = yield getAllExamsAndSubjectsBySectionsService(selectedOrganizationId);
    if (!doesErrorExists(examsAndSubjectsBySectionsReceived)) {
        const examsAndSubjectsBySections = formatExamsAndSubjectsBySections(examsAndSubjectsBySectionsReceived.response);
        yield put(setExamsAndSubjectsBySectionsData(examsAndSubjectsBySections));
    }
    yield put(setAppStateLoading(false));
}

export function* getStudentsBySectionAndSubjectMarks({ sectionId, subjectId, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    const studentsResponse = yield getStudentsBySectionAndSubjectService(selectedOrganizationId, sectionId, subjectId);
    if (!doesErrorExists(studentsResponse)) {
        const studentsList = formatStudentsForAddMarks(studentsResponse.response);
        yield put(setStudentsForAddMarks(studentsList));
    }
    callback();
    yield put(setAppStateLoading(false));
}

export function* addMarks({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { appState, userProfile } = yield select(state => state);
    const { selectedOrganizationId } = appState;
    const { userId } = userProfile;
    payload.facultyId = userId;
    const MarksResponse = yield addMarksService(selectedOrganizationId, payload);
    if (!doesErrorExists(MarksResponse)) {
        // TODO :: Passing entities as not recieving the entityName from response
//         const studentsList = formatStudentsForAddMarks(studentsResponse.response);
//         yield put(setStudentsForAddMarks(studentsList));

    }
     callback();
    yield put(setAppStateLoading(false));
}

export function* getStudentAutoCompleteMarks({ studentName, sectionId, callBack }) {
    const { selectedOrganizationId } = yield select(state => state.appState);
    const studentsResponse = yield getStudentAutoCompleteService(selectedOrganizationId, studentName, sectionId);
    if (!doesErrorExists(studentsResponse)) {
        const studentsList = formatStudentsForAutoComplete(studentsResponse.response);
        callBack(studentsList);
    } else {
         callBack([]);
    }
}

export function* getMarksRecords({ payload, callBack }) {
    const { selectedOrganizationId } = yield select(state => state.appState);
    const MarksRecordsResp = yield getMarksRecordsService(selectedOrganizationId, payload);
    if (!doesErrorExists(MarksRecordsResp)) {
        const MarksDetails = formatMarksRecordsResponse(MarksRecordsResp.response);
        callBack(MarksDetails);
    } else {
         callBack({ MarksRecords: [] });
    }
}

export function* deleteMarksRecords({ MarksId, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield deleteMarksRecordsService(selectedOrganizationId, MarksId);
    callback();
    yield put(setAppStateLoading(false));
}

export function* editMarksRecords({ payload, callback }) {
    yield put(setAppStateLoading(true));
    const { selectedOrganizationId } = yield select(state => state.appState);
    yield editMarksRecordsService(selectedOrganizationId, payload);
    callback();
    yield put(setAppStateLoading(false));
}