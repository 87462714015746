import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";

import {
    GET_ALL_SUBJECTS_SECTIONS,
    GET_METHOD,
    POST_METHOD,
    ADD_EXAM,
    SEARCH_EXAM_RECORDS,
    DELETE_EXAM_RECORDS_URL,
    EDIT_EXAM_RECORDS_URL
} from "../../constants/urls"

export function getAllSubjectsBySectionsService(schoolId) {
    const requestInfo = getRequestInfo(GET_ALL_SUBJECTS_SECTIONS);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}

export function addExamService(schoolId, payload) {
    const requestInfo = getRequestInfo(ADD_EXAM);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}

export function getExamRecordsService(schoolId, payload) {
    const requestInfo = getRequestInfo(SEARCH_EXAM_RECORDS);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}

export function deleteExamRecordsService(schoolId, examId) {
    const requestInfo = getRequestInfo(DELETE_EXAM_RECORDS_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    requestInfo.url = requestInfo.url.replace('{id}', examId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}

export function editExamRecordsService(schoolId, payload) {
    const requestInfo = getRequestInfo(EDIT_EXAM_RECORDS_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payload));
}
