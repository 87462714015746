import {callHandleServiceRequest, getRequestInfo} from "../ServiceHandler";
import {
    GET_METHOD,
    POST_METHOD,
    GET_ALL_SUBJECT_URL,
    ADD_SUBJECT_URL,
    ASSIGN_ENTITY_TO_SUBJECT_URL,
    ASSIGN_FACULTY_TO_SUBJECT_URL,
    ASSIGN_STUDENTS_TO_SUBJECT_URL,
    GET_STUDENTS_BY_MULTIPLE_ENTITIES_URL,
} from "../../constants/urls";

export function addSubjectService(subjectDetails, schoolId) {
    const requestInfo = getRequestInfo(ADD_SUBJECT_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    const { subjectName, subjectType } = subjectDetails;
    const subjectPayload = {
        name: subjectName,
        subjectType,
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(subjectPayload));
}

export function assignEntityToSubjectService(subjectId, entityIds, schoolId) {
    const requestInfo = getRequestInfo(ASSIGN_ENTITY_TO_SUBJECT_URL);
    requestInfo.url = `${requestInfo.url}?organisationId=${schoolId}`;
    const payLoad = {
        id: subjectId,
        sections: entityIds.map(entity => {
            return {
                id: entity.value,
            }
        })
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export function assignFacultyToSubjectService(subjectId, facultyIds, schoolId) {
    const requestInfo = getRequestInfo(ASSIGN_FACULTY_TO_SUBJECT_URL);
    requestInfo.url = `${requestInfo.url}?organisationId=${schoolId}`;
    const payLoad = {
        id: subjectId,
        faculties: facultyIds.map(faculty => {
            return {
                id: faculty.value,
            }
        })
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export function assignStudentsToSubjectService(subjectId, studentIds, schoolId) {
    const requestInfo = getRequestInfo(ASSIGN_STUDENTS_TO_SUBJECT_URL);
    requestInfo.url = `${requestInfo.url}?organisationId=${schoolId}`;
    const payLoad = {
        id: subjectId,
        students: studentIds.map(studentId => {
            return {
                id: studentId,
            }
        })
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export function getStudentsByMultipleEntitiesService(entities, schoolId) {
    const requestInfo = getRequestInfo(GET_STUDENTS_BY_MULTIPLE_ENTITIES_URL);
    requestInfo.url = `${requestInfo.url}?organisationId=${schoolId}`;
    const payLoad = {
        sections: entities.map(entity => {
            return {
                id: entity.id,
            }
        })
    }
    return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(payLoad));
}

export function getAllSubjectsService(schoolId) {
    const requestInfo = getRequestInfo(GET_ALL_SUBJECT_URL);
    requestInfo.url = requestInfo.url.replace('{organisationId}', schoolId);
    return callHandleServiceRequest(requestInfo, GET_METHOD, null);
}