import {SET_USER_PROFILE} from "../actions";

const initialState = {
	firstName: null,
	lastName: null,
	admin: false,
	email: null,
	phone: null,
	userId: null
};

const userProfile = (state = initialState, action)=>{
	let newState;
	switch(action.type) {
		case SET_USER_PROFILE:
			newState = { ...state, ...action.userProfile };
			break;
		default:
			newState = { ...state };
			break;
	}
	return newState;
};

export default userProfile;